import React, { useState } from 'react';
import axios from 'axios';
import { Typography, TextField, Button, Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon
import { mainBackgroundColor, mainTextColor } from '../../globals.js';

// Modal style
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: mainBackgroundColor,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const LogIn = ({ open, onClose }) => {
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [mfa, setMfa] = useState({ required: false, tempAuthKey: '' });
    const [mfaCode, setMfaCode] = useState('');

    const handleCredentialChange = (event) => {
        setCredentials({ ...credentials, [event.target.name]: event.target.value });
    };

    const handleMfaCodeChange = (event) => {
        setMfaCode(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (mfa.required) {
            verifyMfa();
        } else {
            login();
        }
    };

    const login = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, credentials);
            if (response.data.mfaRequired) {
                setMfa({ required: true, tempAuthKey: response.data.mfaTempAuthKey });
            } else {
                onClose(); // Close modal on successful login
                console.log('Login successful, token:', response.data.authToken);
                // Handle post-login actions here, e.g., updating user state or redirecting
            }
        } catch (error) {
            console.error('Login error:', error.response ? error.response.data : error);
        }
    };

    const verifyMfa = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/verify`, {
                mfaTempAuthKey: mfa.tempAuthKey,
                code: mfaCode,
            });
            onClose(); // Close modal on successful MFA verification
            console.log('MFA verification successful, token:', response.data.authToken);
            // Handle post-verification actions here
        } catch (error) {
            console.error('MFA verification error:', error.response ? error.response.data : error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="login-modal-title"
            aria-describedby="login-modal-description"
        >
            <Box sx={modalStyle} component="form" onSubmit={handleSubmit} noValidate>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="login-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                    {mfa.required ? 'Verify MFA' : 'Sign In'}
                </Typography>
                {!mfa.required ? (
                    <>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={credentials.email}
                            onChange={handleCredentialChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={credentials.password}
                            onChange={handleCredentialChange}
                        />
                    </>
                ) : (
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="mfaCode"
                        label="MFA Code"
                        type="text"
                        id="mfaCode"
                        autoFocus
                        value={mfaCode}
                        onChange={handleMfaCodeChange}
                    />
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: mainBackgroundColor, backgroundColor: mainTextColor }}
                >
                    {mfa.required ? 'Verify Code' : 'Sign In'}
                </Button>
            </Box>
        </Modal>
    );
};

export default LogIn;
