import React from 'react';
import { Box, Typography, Divider, Grid, CardMedia, TextField, Button, Card, CardContent, IconButton } from '@mui/material';
import Link from '@mui/material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import TeamImage from '../../assets/team.png'; // Replace with the path to your image file
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor, textGradient, lighterAccentColor } from '../../globals.js';

const teamMembers = [
    {
        name: 'Jack Retterer',
        role: 'Founder & CEO',
        image: "https://res.cloudinary.com/dko9vskvn/image/upload/v1705557302/Screenshot_2024-01-17_at_9.54.48_PM_h7kzuq.png",
        twitterUrl: 'https://twitter.com/jaretterer',
        linkedinUrl: 'https://www.linkedin.com/in/jackretterer/',
    },
    {
        name: 'Alec Kuebler',
        role: 'Founding Engineer',
        image: "https://res.cloudinary.com/dko9vskvn/image/upload/v1705556776/Screenshot_2024-01-17_at_9.37.38_PM_rz0psx.png",
        twitterUrl: 'https://twitter.com/jaretterer',
        linkedinUrl: "https://www.linkedin.com/in/aleckuebler/",
    },
    {
        name: 'Jack Abukhovski',
        role: 'Founding Engineer',
        image: "https://res.cloudinary.com/dko9vskvn/image/upload/v1705556776/Screenshot_2024-01-17_at_9.39.06_PM_ozbprh.png",
        twitterUrl: 'https://twitter.com/JackAbukhovski',
        linkedinUrl: 'https://www.linkedin.com/in/jack-abukhovski/',
    },
];

const About = () => {
    return (
        <Box sx={{ backgroundColor: mainBackgroundColor }}>

            <Box bgcolor="#FAFBFD" sx={{ py: 7, px: 3, pb: 5, backgroundColor: mainBackgroundColor }}>
            <Typography
                            variant="h2"
                            gutterBottom
                            fontWeight='700'
                            sx={{
                                color: mainTextColor,
                                fontSize: { xs: '2.8rem', sm: '3.75rem', md: '4.5rem' }, // Scaling font size
                                '& span': {
                                    background: textGradient,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                },
                                textAlign: 'center', // Center aligned text
                            }}
                        >
                            <span>About Us</span>
                </Typography>
            </Box>
            <Grid container spacing={4} justifyContent="center" sx={{ maxWidth: '100%', margin: '0 auto' }}>
                {teamMembers.map((member, index) => (
                    <Grid item xs={12} sm={6} md={2.5} key={index}>
                        <Card sx={{ maxWidth: 345, mx: 'auto' }}> {/* Center the card and set max width */}
                            <CardMedia
                                component="img"
                                image={member.image}
                                alt={member.name}
                                sx={{ height: 300 }} // Adjust image height as needed
                            />
                           <CardContent sx={{ padding: '16px', backgroundColor: mainBackgroundColor, borderRadius: '4px' }}>
                            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', color: mainTextColor }}>
                                {member.name}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mb: 2, color: mainTextColor }}>
                                {member.role}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
                                <IconButton
                                href={member.twitterUrl}
                                target="_blank"
                                aria-label="twitter-link"
                                sx={{ mx: 0.5, color: mainTextColor, '&:hover': { color: secondaryTextColor } }} // Twitter color on hover
                                >
                                <TwitterIcon />
                                </IconButton>
                                <IconButton
                                href={member.linkedinUrl}
                                target="_blank"
                                aria-label="linkedin-link"
                                sx={{ mx: 0.5, color: mainTextColor, '&:hover': { color: secondaryTextColor } }} // LinkedIn color on hover
                                >
                                <LinkedInIcon />
                                </IconButton>
                            </Box>
                            </CardContent>

                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ px: 30, py: 5 }}>
                <Box sx={{ px: '3%', py: '1%' }}>
                    <Typography variant="h4" align="left" fontWeight="bold" gutterBottom color={mainTextColor}>
                        Our Mission
                    </Typography>
                    <Divider sx={{ width: 'fit-content', bgcolor: 'grey' }} />
                    <Typography variant="body1" align="left" gutterBottom color={mainTextColor}>
                        Advocate’s mission is to empower young professionals and college students by providing them with an AI toolkit that simplifies and streamlines understanding and navigating legal documents. Our goal is to make the complex documeents more accessible and understandable to everyone, regardless of their background or level of expertise. We believe that by leveraging technology, we can help individuals navigate the complex legal landscape with greater ease and confidence.
                    </Typography>
                </Box>

                <Box sx={{ px: '3%', py: '1%' }}>
                    <Typography variant="h4" align="left" fontWeight="bold" gutterBottom color={mainTextColor}>
                        Our Story
                    </Typography>
                    <Divider sx={{ width: 'fit-content', bgcolor: 'grey' }} />
                    <Typography variant="body1" align="left" color={mainTextColor}>
                        Advocate was founded by a team of UC Davis graduates who are passionate builders who recognized the need for a tool that explains the often confusing and inaccessible legal system.
                        They set out to create an AI toolkit that could provide guidance and support to anyone navigating the complexities of the legal world.
                        With their combined technical expertise and commitment to innovation, the Advocate team is dedicated to empowering individuals and leveling the playing field.
                    </Typography>
                </Box>

                <Box sx={{ px: '3%', py: '1%', display: 'flex', gap: 3, pb: 5 }}>
                    <Link href="https://linkedin.com/advocate-ai" target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon sx={{ color: mainTextColor }} />
                    </Link>
                    <Link href="https://instagram.com/advocate_ai" target="_blank" rel="noopener noreferrer">
                        <InstagramIcon sx={{ color: mainTextColor }} />
                    </Link>
                    <Link href="https://twitter.com/advocate_ai" target="_blank" rel="noopener noreferrer">
                        <TwitterIcon sx={{ color: mainTextColor }} />
                    </Link>
                </Box>
            </Box>
            {/* <Box sx={{ py: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" align="center" fontWeight="bold" gutterBottom color={mainTextColor}>
                    Stay Up To Date
                </Typography>
                <Typography variant="body1" align="center" gutterBottom color={mainTextColor}>
                    Enter your email address to register to our newsletter subscription!
                </Typography>
                <TextField
                    label="Enter email address"
                    variant="outlined"
                    sx={{
                        mt: 2,
                        mb: 4,
                        width: '25%',
                        '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'grey', // Set default border color
                        },
                        '&:hover fieldset': {
                            borderColor: 'grey', // Set border color on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'grey', // Set border color on focus
                        },
                        },
                    }}
                    />
               <Button variant="contained" sx={{ color: mainBackgroundColor, backgroundColor: mainTextColor, '&:hover': { backgroundColor: mainTextColor } }}>
                    Subscribe
                </Button>
            </Box> */}

        </Box>
    );
};

export default About;
