import React from 'react';
import { Box, Typography, Divider, Link, Container } from '@mui/material';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor } from '../../globals.js';

const PrivacyPolicy = () => {
    return (
        <Box sx={{ 
            width: '100%', 
            paddingBottom: '2%', // Adjust for footer if necessary
            backgroundColor: mainBackgroundColor, 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center' 
        }}>            
            <Container maxWidth="md" sx={{ my: 6 }}>
                <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', mt: 8, color: mainTextColor }}>
                    Privacy Policy
                </Typography>
                <Divider variant="middle" sx={{ mt: 4 }} />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 2, color: mainTextColor }}>
                    Introduction
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor }}>
                    Welcome to Advocate AI ("we", "us", or "our"). We respect your privacy and are committed to protecting it through this Privacy Policy.
                    This Privacy Policy governs your access to and use of advocateai.xyz, including any content, functionality and services offered on or through advocateai.xyz, whether as a guest or a registered user.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 2, color: mainTextColor }}>
                    Information We Collect About You
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor }}>
                    When you access the Website, we will learn certain information about you during your visit.
                    Personal data: When you register and use our service, we may ask for personal data like your name, email address, phone number, and other relevant information.
                    Contract data: In the course of providing our services, we may receive and store documents and information related to your employment contracts and rental leases.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 2, color: mainTextColor }}>
                    How We Use Your Information
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor }}>
                    We use your personal data and contract data for the provision and improvement of our services. We do not sell or rent your personal information to third parties.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 2, color: mainTextColor }}>
                    Disclosure of Your Information
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor }}>
                    We may disclose your personal information to comply with any court order, law, or legal process, including to respond to any government or regulatory request, or if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Advocate AI, our customers, or others.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 2, color: mainTextColor }}>
                    Data Security
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor }}>
                    We implement a variety of security measures to maintain the safety of your personal information. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 2, color: mainTextColor }}>
                    Third-Party Services
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor }}>
                    We may employ third-party companies and individuals due to the following reasons:
                    To facilitate our Service;
                    To provide the Service on our behalf;
                    To perform Service-related services; or
                    To assist us in analyzing how our Service is used.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 2, color: mainTextColor }}>
                    Changes to Our Privacy Policy
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor }}>
                    It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you through a notice on the Website home page.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 2, color: mainTextColor }}>
                    Contact Us
                </Typography>
                <Typography variant="body2" paragraph sx={{ mb: 12, color: mainTextColor }}>
                    To ask questions or comment about this privacy policy and our privacy practices, contact us at: <Link href="mailto:ouradvocateai@gmail.com">ouradvocateai@gmail.com</Link>.
                </Typography>
            </Container>
        </Box>
    );
};

export default PrivacyPolicy;
