import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, ListItemText, Divider, Drawer, List, ListItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';  // Import Close Icon
import { useTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { auth, signOut } from "./firebase"; // Import firebase
import { onAuthStateChanged } from 'firebase/auth'; // Import signInWithPopup and onAuthStateChanged
import { Link } from 'react-router-dom';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import logo from '../assets/logo.png';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textGradient, textFont, accentColor } from '../globals.js';
import SignUp from './Authentication/SignUp';
import LogIn from './Authentication/LogIn';
import UserAuth from './Authentication/UserSection';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useUser } from './Authentication/UserContext';

// Import the new components
import ProductsMenu from './Menus/ProductsMenu';
import CompanyMenu from './Menus/CompanyMenu';

const NavBar = () => {
  const theme = useTheme();

  const [productsAnchorEl, setProductsAnchorEl] = useState(null);
  const productsOpen = Boolean(productsAnchorEl);
  const [openProducts, setOpenProducts] = useState(false);

  const [companyAnchorEl, setCompanyAnchorEl] = useState(null);
  const companyOpen = Boolean(companyAnchorEl);
  const [openCompany, setOpenCompany] = useState(false);

  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const userMenuOpen = Boolean(userAnchorEl);

  const [openAccount, setOpenAccount] = useState(false);

  // const [user, setUser] = useState(null);
  const { user, setUser } = useUser();
  const [mobileOpen, setMobileOpen] = useState(false);
  // implement navbar hamburger menu
  const isMobile = useMediaQuery(theme.breakpoints.down(1250));

  const [openSignUpDialog, setOpenSignUpDialog] = useState(false);
  const [openLogInDialog, setOpenLogInDialog] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Products Manu
  const handleProductsMenu = (event) => {
    setProductsAnchorEl(event.currentTarget);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleClickProducts = () => {
    setOpenProducts(!openProducts);
  };

  const handleProductsClose = () => {
    setProductsAnchorEl(null);
  };

  // Company Menu
  const handleCompanyMenu = (event) => {
    setCompanyAnchorEl(event.currentTarget);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleClickCompany = () => {
    setOpenCompany(!openCompany);
  };

  const handleCompanyClose = () => {
    setCompanyAnchorEl(null);
  };

  // User Menu
  const handleUserMenu = (event) => {
    setUserAnchorEl(event.currentTarget);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };

  const handleClickAccount = () => {
    setOpenAccount(!openAccount);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setUser(null);
        console.log('Sign out successful');
      }).catch((error) => {
        // An error happened.
        console.log('Sign out error', error);
      });
  };

  const handleSignOutAndCloseMenu = () => {
    handleSignOut();
    setMobileOpen(false);
  };

  // Open and Close Sign Up Menu
  const handleOpenSignUpDialogAndCloseMenu = () => {
    console.log("Bringing up the sign up Modal");
    setOpenSignUpDialog(true);
    setMobileOpen(false);
  };

  const handleCloseSignUpDialog = () => {
    setOpenSignUpDialog(false);
  };

  // Open and Close Log In Menu
  const handleOpenLogInDialogAndCloseMenu = () => {
    console.log("Bringing up the log in Modal");
    setOpenLogInDialog(true);
    setMobileOpen(false);
  };

  const handleCloseLogInDialog = () => {
    setOpenLogInDialog(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser ? currentUser : null);
    });

    return () => unsubscribe();
  }, []);

  const drawer = (
    <div>
      <Box display="flex" justifyContent="flex-end" paddingRight="3%" paddingTop="1.5%">
        <IconButton
          color="inherit"
          aria-label="close drawer"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ color: mainTextColor }}
        >
          <CloseIcon sx={{ color: mainTextColor }} />
        </IconButton>
      </Box>
      <List>
        <ListItem component={Link} onClick={handleClickProducts} style={{ padding: '20px', color: mainTextColor }}>
          <ListItemText primary="Products" />
          {openProducts ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openProducts} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem component={Link} to="/employment-contracts" style={{ padding: '20px', paddingLeft: '40px', color: mainTextColor }} onClick={() => setMobileOpen(false)}>
              <ListItemText primary="Employment Contracts" />
            </ListItem>
            <ListItem component={Link} to="/lease-agreements" style={{ padding: '20px', paddingLeft: '40px', color: mainTextColor }} onClick={() => setMobileOpen(false)}>
              <ListItemText primary="Lease Agreements" />
            </ListItem>
            <ListItem component={Link} to="/prenup-agreements" style={{ padding: '20px', paddingLeft: '40px', color: mainTextColor }} onClick={() => setMobileOpen(false)}>
              <ListItemText primary="Prenups" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem component={Link} to="/pricing" style={{ padding: '20px', color: mainTextColor }} onClick={() => setMobileOpen(false)}>
          <ListItemText primary="Pricing" />
        </ListItem>

        <ListItem component={Link} onClick={handleClickCompany} style={{ padding: '20px', color: mainTextColor }}>
          <ListItemText primary="Company" />
          {openCompany ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCompany} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem component={Link} to="/about" style={{ padding: '20px', paddingLeft: '40px', color: mainTextColor }} onClick={() => setMobileOpen(false)}>
              <ListItemText primary="About" />
            </ListItem>
            <ListItem component={Link} to="/contact" style={{ padding: '20px', paddingLeft: '40px', color: mainTextColor }} onClick={() => setMobileOpen(false)}>
              <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem component={Link} to="/careers" style={{ padding: '20px', paddingLeft: '40px', color: mainTextColor }} onClick={() => setMobileOpen(false)}>
              <ListItemText primary="Careers" />
            </ListItem>
          </List>
        </Collapse>

        <Divider />

        {user ? (
          <div>
            <ListItem onClick={handleClickAccount} style={{ padding: '20px', color: mainTextColor }}>
              <ListItemText primary="Account" />
              {openAccount ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openAccount} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem component={Link} to="/settings" style={{ padding: '20px', paddingLeft: '40px', color: mainTextColor }} onClick={() => setMobileOpen(false)}>
                  <ListItemText primary="Settings" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem style={{ padding: '20px', color: mainTextColor }} onClick={handleSignOutAndCloseMenu}>
              <ListItemText primary="Log Out" />
            </ListItem>
          </div>
        ) : (
          <>
            <ListItem component={Link} onClick={handleOpenLogInDialogAndCloseMenu} style={{ padding: '20px', color: mainTextColor }}>
              <ListItemText primary="Log In" />
            </ListItem>

            <ListItem component={Link} onClick={handleOpenSignUpDialogAndCloseMenu} style={{ padding: '20px', color: mainTextColor }}>
              <ListItemText primary="Sign Up" />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  const DesktopView = () => (
    <Box display="flex" alignItems="center" justifyContent='center' flexGrow={1}>
      <Button variant="text" color="inherit" sx={{ color: mainTextColor, textTransform: 'none', fontSize: '1.25em' }} aria-controls="company-menu" aria-haspopup="true" onClick={handleCompanyMenu} endIcon={<ArrowDropDown />}>Company</Button>
      <Button variant="text" color="inherit" sx={{ color: mainTextColor, textTransform: 'none', fontSize: '1.25em', marginRight: '20px' }} aria-controls="products-menu" aria-haspopup="true" onClick={handleProductsMenu} endIcon={<ArrowDropDown />}>Products</Button>
      <Button variant="text" color="inherit" sx={{ color: mainTextColor, textTransform: 'none', fontSize: '1.25em', marginRight: '20px', '&:hover': {backgroundColor: 'transparent', color: mainTextColor,} }} component={Link} to="/pricing">Pricing</Button>
      <Button variant="text" color="inherit" sx={{ color: mainTextColor, textTransform: 'none', fontSize: '1.25em', marginRight: '20px', '&:hover': {backgroundColor: 'transparent', color: mainTextColor,} }} component={Link} to="/contact">Contact</Button>
    </Box>
  );

  const MobileView = () => (
    <Box>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ color: mainTextColor }}  // Set the color to white
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"  // To make it open from the right side
        open={mobileOpen}
        onClose={handleDrawerToggle}
        PaperProps={{ // Adding this line
          style: {
            width: '100%',
            backgroundColor: mainBackgroundColor,
          },
        }} // To here
      >
        {drawer}
      </Drawer>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: mainBackgroundColor, borderBottom: `2px solid ${accentColor}`, paddingBottom: '2px' }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="h6" component={Link} to="/" sx={{ color: mainTextColor, flexGrow: 1, fontSize: '1.25em', textDecoration: 'none', display: 'flex', alignItems: 'center','& span': {
                                    background: textGradient,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                }, '&:hover': { color: mainTextColor, textDecoration: 'none' } }}>
                <img src={logo} alt="logo" style={{ marginRight: '5px', height: '1.45em', width: 'auto' }} /> <span>Advocate AI</span>
              </Typography>

              {isMobile ? <MobileView /> : <DesktopView />}

              {!isMobile && <UserAuth
                user={user}
                handleUserMenu={handleUserMenu}
                userMenuOpen={userMenuOpen}
                userAnchorEl={userAnchorEl}
                handleUserMenuClose={handleUserMenuClose}
                handleOpenLogInDialog={handleOpenLogInDialogAndCloseMenu}
                // openLogInDialog={openLogInDialog}
                handleCloseLogInDialog={handleCloseLogInDialog}
                handleSignOut={handleSignOut}
                handleOpenSignUpDialog={handleOpenSignUpDialogAndCloseMenu}
                // openSignUpDialog={openSignUpDialog}
                handleCloseSignUpDialog={handleCloseSignUpDialog}
                mainTextColor={mainTextColor}
              />}
            </Box>
          </Toolbar>
          {openSignUpDialog && <SignUp open={handleOpenSignUpDialogAndCloseMenu} onClose={handleCloseSignUpDialog} />}
          {openLogInDialog && <LogIn open={handleOpenLogInDialogAndCloseMenu} onClose={handleCloseLogInDialog} />}
          {/* {openLogInDialog && <LogIn open={openLogInDialog} onClose={handleCloseLogInDialog} />} */}
        </AppBar>
      </Box>
      <ProductsMenu handleProductsClose={handleProductsClose} productsOpen={productsOpen} productsAnchorEl={productsAnchorEl} />
      <CompanyMenu handleCompanyClose={handleCompanyClose} companyOpen={companyOpen} companyAnchorEl={companyAnchorEl} />
    </ThemeProvider>
  );

};

export default NavBar;