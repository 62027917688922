import React, { useState, useEffect } from 'react'; // import useEffect here
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Card, List, ListItem, Paper, Button, Grid, Input, Container } from '@mui/material';
import UploadBox from '../Shared/UploadBox.js';
import DetailListItem from '../Shared/DetailListItem.js';

const PrenupUpload = () => {
    const [fileInfo, setFileInfo] = useState([]);

    const [wordCount, setWordCount] = useState(null);

    const [purposeOfPrenup, setPurposeOfPrenup] = useState(null);
    const [maritalAndSeparateProperty, setMaritalAndSeparateProperty] = useState(null);
    const [sharedAssetsHandling, setSharedAssetsHandling] = useState(null);
    const [futureIncomeAndInheritance, setFutureIncomeAndInheritance] = useState(null);
    const [spousalSupportOrAlimony, setSpousalSupportOrAlimony] = useState(null);
    const [handlingDebts, setHandlingDebts] = useState(null);
    const [retirementAccountsAndPensions, setRetirementAccountsAndPensions] = useState(null);
    const [sunsetClause, setSunsetClause] = useState(null);
    const [provisionsForChildren, setProvisionsForChildren] = useState(null);
    const [prenupRelocationImplications, setPrenupRelocationImplications] = useState(null);
    const [infidelityClause, setInfidelityClause] = useState(null);
    const [legalImplicationsOfWaivingLawyer, setLegalImplicationsOfWaivingLawyer] = useState(null);
    const [prenupChangeOrTermination, setPrenupChangeOrTermination] = useState(null);
    const [agreementChallengeProcess, setAgreementChallengeProcess] = useState(null);
    const [fullDisclosureOfAssetsAndLiabilities, setFullDisclosureOfAssetsAndLiabilities] = useState(null);


    const isMounted = React.useRef(true); 

    useEffect(() => {
        // On component unmount, change isMounted to false
        return () => {
            isMounted.current = false;
        }
    }, []);

    const onDrop = async acceptedFiles => {
        console.log("HEY 1");
        if (isMounted.current) setFileInfo(acceptedFiles);
    
        for (const file of acceptedFiles) {
            const formData = new FormData();
            formData.append('file', file);
    
            try {
                console.log("HEY");
                const backendUrl = process.env.REACT_APP_BACKEND_URL; // Accessing the environment variable
                const response = await axios.post(`${backendUrl}/upload/prenup-upload/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log(response.data);
    
                if (isMounted.current) {
                    setWordCount(response.data.word_count);

                    setPurposeOfPrenup(response.data.purpose_of_prenup);
                    setMaritalAndSeparateProperty(response.data.marital_and_separate_property);
                    setSharedAssetsHandling(response.data.shared_assets_handling);
                    setFutureIncomeAndInheritance(response.data.future_income_and_inheritance);
                    setSpousalSupportOrAlimony(response.data.spousal_support_or_alimony);
                    setHandlingDebts(response.data.handling_debts);
                    setRetirementAccountsAndPensions(response.data.retirement_accounts_and_pensions);
                    setSunsetClause(response.data.sunset_clause);
                    setProvisionsForChildren(response.data.provisions_for_children);
                    setPrenupRelocationImplications(response.data.prenup_relocation_implications);
                    setInfidelityClause(response.data.infidelity_clause);
                    setLegalImplicationsOfWaivingLawyer(response.data.legal_implications_of_waiving_lawyer);
                    setPrenupChangeOrTermination(response.data.prenup_change_or_termination);
                    setAgreementChallengeProcess(response.data.agreement_challenge_process);
                    setFullDisclosureOfAssetsAndLiabilities(response.data.full_disclosure_of_assets_and_liabilities);

                }
            } catch (error) {
                console.error('Error uploading file:', error.response ? error.response : error);
            }
        }
    };
    
    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    });

    const details = [
        { label: 'Purpose of Prenup', value: purposeOfPrenup },
        { label: 'Marital and Separate Property', value: maritalAndSeparateProperty },
        { label: 'Shared Assets Handling', value: sharedAssetsHandling },
        { label: 'Future Income and Inheritance', value: futureIncomeAndInheritance },
        { label: 'Spousal Support or Alimony', value: spousalSupportOrAlimony },
        { label: 'Handling Debts', value: handlingDebts },
        { label: 'Retirement Accounts and Pensions', value: retirementAccountsAndPensions },
        { label: 'Sunset Clause', value: sunsetClause },
        { label: 'Provisions for Children', value: provisionsForChildren },
        { label: 'Prenup Relocation Implications', value: prenupRelocationImplications },
        { label: 'Infidelity Clause', value: infidelityClause },
        { label: 'Legal Implications of Waiving Lawyer', value: legalImplicationsOfWaivingLawyer },
        { label: 'Prenup Change or Termination', value: prenupChangeOrTermination },
        { label: 'Agreement Challenge Process', value: agreementChallengeProcess },
        { label: 'Full Disclosure of Assets and Liabilities', value: fullDisclosureOfAssetsAndLiabilities },
        { label: 'Word Count', value: wordCount },
      ];
      
      return (
        <Container maxWidth="lg">
          <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <UploadBox
                isDragActive={isDragActive}
                isDragReject={isDragReject}
                fileInfo={fileInfo}
                getInputProps={getInputProps}
                contractType="Prenup Agreements"
              />
            </Grid>
            {purposeOfPrenup !== null && maritalAndSeparateProperty !== null && sharedAssetsHandling !== null && (
              <Grid item xs={12}>
                <Card sx={{ padding: 2, marginBottom: 2 }}>
                  <Typography variant="h6" gutterBottom>Prenup Agreement Details:</Typography>
                  <List>
                    {details.map((detail, index) => (
                      <DetailListItem key={index} detail={detail} />
                    ))}
                  </List>
                </Card>
              </Grid>
            )}
          </Grid>
        </Container>
      );
};

export default PrenupUpload;
