import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Button, Link, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { mainBackgroundColor, secondaryBackgroundColor, accentColor, textFont } from '../../globals.js';

// Custom styles
const modernButton = {
  backgroundColor: '#5E17EB', // A modern accent color
  color: 'white',
  borderRadius: '12px',
  padding: '10px 20px',
  textTransform: 'none',
  boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)',
  "&:hover": {
    backgroundColor: '#4329B1'
  }
};

const modernTypographyH2 = {
  fontWeight: 700,
  fontSize: '2.5rem',
  color: '#333',
  marginBottom: '1rem'
};

const modernTypographyBody = {
  fontSize: '1rem',
  color: '#666'
};

const Resources = () => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      padding: '3%',
      minHeight: '100vh',
      width: '100vw',
      backgroundColor: mainBackgroundColor, // Soft background color
      fontFamily: 'Poppins, sans-serif' // Modern font
    }}>
      <Box sx={{
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem',
        borderRadius: '12px',
        width: '70vw',
        maxWidth: '960px',
        backgroundColor: mainBackgroundColor // Card's background
      }}>
        <Typography sx={modernTypographyH2}>
          Unstructured's Blog
        </Typography>
        {/* Blog Posts Section */}
        <Box mt={3} mb={5}>
          <Grid container spacing={3}>
            {["Introducing Advocate AI"].map((title, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Card sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  borderRadius: '12px',
                  boxShadow: '0 4px 8px rgba(0,0,0,.12)' // Card shadow
                }}>
                  <CardContent>
                    <Typography variant="h5" sx={{ fontWeight: 600, color: '#333' }}>
                      {title}
                    </Typography>
                    <Typography sx={modernTypographyBody}>
                      How we plan on educating younger generations on legal contracts.
                    </Typography>
                  </CardContent>
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Button sx={modernButton} component={Link} href="#">Read More</Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Resources;
