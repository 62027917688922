import React from 'react';
import { Box, Container } from '@mui/material';
import AccountPage from './AccountPage';
import { mainBackgroundColor } from '../globals';

const Settings = () => {
    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingTop: '3%', backgroundColor: mainBackgroundColor }}>
            <Container>
                <AccountPage/>
            </Container>
        </Box>
    );
};

export default Settings;
