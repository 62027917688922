import React, { useState } from 'react';
import { Dialog, Container, DialogTitle, DialogContent, TextField, Button, DialogActions, FormControl, InputLabel, Select, MenuItem, Grid, IconButton, Typography, Box } from '@mui/material';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor } from '../globals.js';
import CloseIcon from '@mui/icons-material/Close';
import useValidation from './Authentication/Validations';
import { styled } from '@mui/system';
import axios from "axios";


const StyledContainer = styled(Container)(({ theme }) => ({
  height: 'calc(100vh - 64px)', // Deducting navbar height
  marginTop: '2rem',
  paddingTop: '3%',
  color: mainTextColor,
  fontFamily: textFont,
}));


export default function RequestDemo({ open, handleClose }) {
  const { firstNameError, validateFirstName } = useValidation();
  const { lastNameError, validateLastName } = useValidation();
  const { emailError, validateEmail } = useValidation();
  const { companyNameError, validateCompanyName } = useValidation();
  const { purposeError, validatePurpose } = useValidation();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    referrer: "",
    purpose: ""
  });

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post('http://localhost:5001/upload/submit-request-demo/', formData);
      console.log(response.data); // Success message
    } catch (error) {
      console.error(error);
    }
    handleClose();
  }

  const firstNameChecker = () => {
    validateFirstName(formData.firstName);
  }

  const lastNameChecker = () => {
    validateLastName(formData.lastName);
  }

  const emailChecker = () => {
    validateEmail(formData.email);
  }

  const companyNameChecker = () => {
    validateCompanyName(formData.companyName);
  }

  const purposeChecker = () => {
    validatePurpose(formData.purpose);
  }

  const ErrorText = styled(Typography)({
    color: "red",
    marginBottom: "10px",
    mb: 4,
    fontSize: '14px'
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { padding: '2em 2em 0', overflow: 'hidden' } }}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: 'absolute', right: 16, top: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography align="center" variant="h3" fontWeight="fontWeightBold" sx={{ background: 'linear-gradient(90deg, #F59E0B 35%, #CC3E00 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Request A Demo</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {firstNameError ? ( // Conditionally render the error text or the label
                  <TextField
                    fullWidth
                    margin="dense"
                    label={<ErrorText>{firstNameError}</ErrorText>}
                    variant="outlined"
                    name="firstName"
                    type="text"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    onBlur={firstNameChecker}
                    error // Set the 'error' prop to true when there's an error
                  />
                ) : (
                  <TextField
                    fullWidth
                    margin="dense"
                    label="First Name"
                    variant="outlined"
                    name="firstName"
                    type="text"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    onBlur={firstNameChecker}
                    InputProps={{
                      style: {
                        color: accentColor
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: accentColor,
                        "&.Mui-focused": {
                          color: accentColor
                        }
                      }
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {lastNameError ? ( // Conditionally render the error text or the label
                  <TextField
                    fullWidth
                    margin="dense"
                    label={<ErrorText>{lastNameError}</ErrorText>}
                    variant="outlined"
                    name="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    onBlur={lastNameChecker}
                    error // Set the 'error' prop to true when there's an error
                  />
                ) : (
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Last Name"
                    variant="outlined"
                    name="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    onBlur={lastNameChecker}
                    InputProps={{
                      style: {
                        color: accentColor
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: accentColor,
                        "&.Mui-focused": {
                          color: accentColor
                        }
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
            {emailError ? ( // Conditionally render the error text or the label
              <TextField
                fullWidth
                margin="dense"
                label={<ErrorText>{emailError}</ErrorText>}
                variant="outlined"
                name="email"
                type="text"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={emailChecker}
                error // Set the 'error' prop to true when there's an error
              />
            ) : (
              <TextField
                fullWidth
                margin="dense"
                label="Business Email"
                variant="outlined"
                name="email"
                type="text"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={emailChecker}
                InputProps={{
                  style: {
                    color: accentColor
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: accentColor,
                    "&.Mui-focused": {
                      color: accentColor
                    }
                  }
                }}
              />
            )}
            {companyNameError ? ( // Conditionally render the error text or the label
              <TextField
                fullWidth
                margin="dense"
                label={<ErrorText>{companyNameError}</ErrorText>}
                variant="outlined"
                name="companyName"
                type="text"
                value={formData.companyName}
                onChange={handleInputChange}
                onBlur={companyNameChecker}
                error // Set the 'error' prop to true when there's an error
              />
            ) : (
              <TextField
                fullWidth
                margin="dense"
                label="Company Name"
                variant="outlined"
                name="companyName"
                type="text"
                value={formData.companyName}
                onChange={handleInputChange}
                onBlur={companyNameChecker}
                InputProps={{
                  style: {
                    color: accentColor
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: accentColor,
                    "&.Mui-focused": {
                      color: accentColor
                    }
                  }
                }}
              />
            )}
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel htmlFor="referrer-label" style={{ color: accentColor }}>How did you hear about us?</InputLabel>
              <Select
                label="How did you hear about us?"
                id="referrer-label"
                name="referrer"
                onChange={handleInputChange}
                style={{ color: accentColor }}
              >
                <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                <MenuItem value="Twitter">Twitter</MenuItem>
                <MenuItem value="Google">Google</MenuItem>
                <MenuItem value="Facebook">Facebook</MenuItem>
                <MenuItem value="Friend">Friend</MenuItem>
              </Select>
            </FormControl>

            {purposeError ? ( // Conditionally render the error text or the label
              <TextField
                fullWidth
                margin="dense"
                label={<ErrorText>{purposeError}</ErrorText>}
                variant="outlined"
                name="purpose"
                type="text"
                value={formData.purpose}
                onChange={handleInputChange}
                onBlur={purposeChecker}
                error // Set the 'error' prop to true when there's an error
              />
            ) : (
              <TextField
                fullWidth
                margin="dense"
                label="What would you like to do with Advocate?"
                variant="outlined"
                name="purpose"
                type="text"
                value={formData.purpose}
                onChange={handleInputChange}
                onBlur={purposeChecker}
                InputProps={{
                  style: {
                    color: accentColor
                  }
                }}
                InputLabelProps={{
                  style: {
                    color: accentColor,
                    "&.Mui-focused": {
                      color: accentColor
                    }
                  }
                }}
              />
            )}
            <Box sx={{ paddingTop: '1em' }}>
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: accentColor,
                    color: '#000',
                    width: '100%',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#fff',
                      borderColor: accentColor,
                      border: '1px solid',
                      color: accentColor
                    }
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
