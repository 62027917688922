import { useState } from 'react';

const useValidation = () => {
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [messageError, setMessageError] = useState("");
    const [companyNameError, setCompanyNameError] = useState("");
    const [purposeError, setPurposeError] = useState("");

    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(email) || email === '' || email.trim() === '') {
            setEmailError("Invalid Email");
        } else {
            setEmailError("");
        }
    };

    const validatePassword = (password, confirmPassword) => {
        if (password.length < 8) {
            setPasswordError("Password must be at least 8 characters");
        } else if (password !== confirmPassword && confirmPassword !== "")  {
            setPasswordError("Passwords do not match.");
        }  else {
            setPasswordError("");
        }
    };

    const validateFirstName = (firstName) => {
        if (firstName === "") {
            setFirstNameError("First name cannot be empty");
        } else if (firstName.trim() === "") {
            setFirstNameError("First name cannot be empty");
        } else {
            setFirstNameError("");
        }
    };

    const validateLastName = (lastName) => {
        if (lastName === "") {
            setLastNameError("Last name cannot be empty");
        } else if (lastName.trim() === "") {
            setLastNameError("Last name cannot be empty");
        } else {
            setLastNameError("");
        }
    };

    const validateSubject = (subject) => {
        if (subject === "") {
            setSubjectError("Subject cannot be empty");
        } else if (subject.trim() === "") {
            setSubjectError("Subject cannot be empty");
        } else {
            setSubjectError("");
        }
    };

    const validateCompanyName = (companyName) => {
        if (companyName === "") {
            setCompanyNameError("Company name cannot be empty");
        } else if (companyName.trim() === "") {
            setCompanyNameError("Company name cannot be empty");
        } else {
            setCompanyNameError("");
        }
    };

    const validatePurpose = (purpose) => {
        if (purpose === "") {
            setPurposeError("Please leave a reson");
        } else if (purpose.trim() === "") {
            setPurposeError("Please leave a reson");
        } else {
            setPurposeError("");
        }
    };

    const validateMessage = (message) => {
        if (message.length <= 5) {
            setMessageError("Message has to be at least 5 characters long");
        } else {
            setMessageError("");
        }
    };

    return {
        emailError,
        setEmailError,
        validateEmail,
        passwordError,
        setPasswordError,
        validatePassword,
        firstNameError,
        setFirstNameError,
        validateFirstName,
        lastNameError,
        setLastNameError,
        validateLastName,
        subjectError,
        setSubjectError,
        validateSubject,
        messageError,
        setMessageError,
        validateMessage,
        companyNameError,
        setCompanyNameError,
        validateCompanyName,
        purposeError,
        setPurposeError,
        validatePurpose
    };
};

export default useValidation;