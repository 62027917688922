// FileUpload.js
import React from 'react';
import { Grid, Paper, Input, Box, Typography, Button, List, ListItem } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';


const UploadBox = ({ isDragActive, isDragReject, isAbleToScan, fileInfo, isLoading, getInputProps, contractType }) => {

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return (
        <Grid item xs={12}>
            <Paper
                elevation={3}
                sx={{
                    p: 6,
                    width: '100%', // Update this
                    height: '100%', // And this
                    bgcolor: isDragActive ? 'info.light' : isDragReject ? 'error.light' : 'background.paper',
                }}
            >
                <Input {...getInputProps()} id="file-upload" type="file" style={{ display: 'none' }} />
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                    <label htmlFor="file-upload" style={{ display: 'block' }}>
                        <Box mt={2}>
                            <DescriptionIcon fontSize="large" sx={{ color: 'black' }} />
                        </Box>
                        <Box mt={1}>
                            <Typography variant="h6" gutterBottom>
                                {contractType}
                            </Typography>
                        </Box>
                        {isAbleToScan ? (
                            <>
                                <Input id="file-upload" type="file" style={{ display: 'none' }} />
                                <Typography color="text.secondary" gutterBottom>
                                    Upload or drag and drop your file
                                </Typography>
                                <Box mt={2}>
                                    <Button variant="outlined" component="span" startIcon={<CloudUploadIcon sx={{ color: 'black' }} />} sx={{ color: 'black', borderColor: 'black' }}>
                                        Upload
                                    </Button>
                                </Box>
                                {/* <Box mt={2}>
                            <Button variant="outlined" component="span" sx={{ color: 'black', borderColor: 'black' }} onClick={handleButtonClick}>
                                Test
                            </Button>
                        </Box> */}
                            </>
                        ) : (
                            <>
                                <Typography color="text.secondary" gutterBottom>
                                    Free scan already used
                                </Typography>
                                <Box mt={2}>
                                    <Button variant="outlined" component={Link} to="/Pricing" sx={{ color: 'black', borderColor: 'black' }}>
                                        Go to Pricing
                                    </Button>
                                </Box>
                            </>
                        )}
                    </label>
                </Box>
                <List>
                    {fileInfo.map((file, index) => (
                        <ListItem key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2">{file.name} ({formatBytes(file.size)})</Typography>
                            {isLoading ? <CircularProgress size={20} style={{ marginLeft: '20px' }} /> : null}
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Grid>
    );
};

export default UploadBox;