import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];

const demographicOptions = [
  'Option 1',
  'Option 2',
  'Option 3',
  'Option 4',
  'Option 5',
  // Add more options as needed
];

const DemographicModal = ({ open, handleClose, onSubmit }) => {
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [demographic, setDemographic] = useState([]);
  const [validAge, setValidAge] = useState(true);

  const handleAgeChange = (event) => {
    const enteredAge = event.target.value;

    if (!isNaN(enteredAge) && enteredAge >= 0) {
      setAge(enteredAge);
      setValidAge(true);
    } else {
      setAge(enteredAge);
      setValidAge(false);
    }
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleDemographicChange = (event) => {
    setDemographic(event.target.value);
  };

  const handleSubmit = () => {  
    // Check age validity before submitting the form
    if (!validAge) {
      return;
    }
  
    // Pass the form data to the callback function
    const formData = {
      age: age,
      gender: gender,
      demographic: demographic
    };
    handleClose(); // Close the modal after submission
    onSubmit(formData);
  };

  const handleDoNotShare = () => {
    // Handle "Do Not Want to Share" button click
    console.log('User does not want to share demographic information');
    handleClose(); // Close the modal
  };

  return (
    <div>
        <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="user-modal-title"
      aria-describedby="user-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" id="user-modal-title" component="h2">
          Enter Your Information
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            id="age"
            label="Age"
            type="number"
            value={age}
            onChange={handleAgeChange}
            required
            fullWidth
            margin="normal"
            error={!validAge}
            helperText={!validAge && 'Please enter a valid age'}
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="gender-label" sx={{ mt: -1 }}>
              Gender
            </InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              value={gender}
              onChange={handleGenderChange}
            >
              {genderOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="demographic-label" sx={{ mt: -1 }}>
              Demographic
            </InputLabel>
            <Select
              labelId="demographic-label"
              id="demographic"
              multiple
              value={demographic}
              onChange={handleDemographicChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {demographicOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={demographic.indexOf(option) > -1} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="outlined" color="secondary" onClick={handleDoNotShare}>
              Do Not Want to Share
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={!validAge}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
    </div>
  );
};

export default DemographicModal;
