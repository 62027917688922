// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signOut } from "firebase/auth";
import { getFirestore, collection, addDoc, setDoc, updateDoc, query, where, getDocs, doc } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import React, { useState, useEffect } from 'react';
import DemographicModal from './DemographicModal';
import ReactDOM from 'react-dom';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBoEKJy_BwGVF-mpoBuaisolK6HbGvmEoI",
  authDomain: "advocate-284e8.firebaseapp.com",
  projectId: "advocate-284e8",
  storageBucket: "advocate-284e8.appspot.com",
  messagingSenderId: "64607129976",
  appId: "1:64607129976:web:48cba5d05f267c0f0df88d",
  measurementId: "G-R0ZJPFFRKL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();

async function addUser(newUser) {
  try {
    console.log("Trying to add user");
    const db = getFirestore();
    const usersCollection = collection(db, "users");

    // Check if user with the same email already exists
    const usersQuery = query(usersCollection, where("email", "==", newUser.email));
    const snapshot = await getDocs(usersQuery);

    if (snapshot.size > 0) {
      console.log("User already exists in the database.");
    } else {
      // Add the new user document to the collections
      //  await addDoc(usersCollection, newUser);
      const userDoc = doc(db, "users", auth.currentUser.uid); // Create a reference to the document with the ID of newUser.uid
      await setDoc(userDoc, newUser); // Set the document at the reference to newUser
      console.log(`New user added successfully! ${JSON.stringify(newUser)}`);
    }
  } catch (error) {
    console.error("Error querying database:", error);
  }
}


async function getUser() {
  try {
    console.log("Trying to get user");
    const db = getFirestore();
    const usersCollection = collection(db, "users");

    // Query for a user document with the specified email
    const usersQuery = query(usersCollection, where("email", "==", auth.currentUser.email));
    const querySnapshot = await getDocs(usersQuery);

    if (!querySnapshot.empty) {
      // If there are matching documents (multiple users with the same email), you can choose how to handle it.
      // For example, you can return the first matching user's data.
      const userDoc = querySnapshot.docs[0];
      console.log("Document data:", userDoc.data());
      return userDoc.data();
    } else {
      // docSnap.data() will be undefined in this case
      console.error("No such document!");
    }
  } catch (error) {
    console.error("Error querying database:", error);
  }
}

async function updateUserInfo(demographicDetails, email) {
  try {
    console.log("Adding user info");
    const db = getFirestore();
    const usersCollection = collection(db, "users");

    // Check if user with the same email already exists
    const usersQuery = query(usersCollection, where("email", "==", email));
    const snapshot = await getDocs(usersQuery);

    if (snapshot.size > 0) {
      const userDoc = snapshot.docs[0]; // Assuming only one user document per email
      await updateDoc(doc(db, "users", userDoc.id), demographicDetails);
      console.log("User already exists in the database.");
    }
  } catch (error) {
    console.error("Error querying database:", error);
  }
}

async function showDemographicModal(newUser, callback) {
  try {
    console.log("Entering function to generate Modal if user is just now creating account");
    const db = getFirestore();
    const usersCollection = collection(db, "users");

    // Check if user with the same email already exists
    const usersQuery = query(usersCollection, where("email", "==", newUser.email));
    const snapshot = await getDocs(usersQuery);

    if (snapshot.size > 0) {
      console.log("Do not need to generate Modal");
    } else {
      const container = document.createElement('div');
      const handleSubmit = (formData) => {
        ReactDOM.unmountComponentAtNode(container);
        console.log(formData);
        callback(formData);
      };
      const handleClose = () => {
        ReactDOM.unmountComponentAtNode(container);
      };
      ReactDOM.render(<DemographicModal open={true} handleClose={handleClose} onSubmit={handleSubmit} />, container);
      document.body.appendChild(container);
    }
  } catch (error) {
    console.error("Error querying database:", error);
  }
}

async function getCurrentUser() {
  try {
    console.log('Trying to get the current user.');
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.log("User is not logged in.");
      return;
    } else {
      console.log('Current user has been found');
      return currentUser;
    }
  } catch (error) {
    console.error("Error querying database:", error);
  }
}


export { auth, storage, provider, signOut, addUser, updateUserInfo, showDemographicModal, getCurrentUser, getUser };