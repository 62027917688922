import React from 'react';
import { ListItem, ListItemText, Typography } from '@mui/material';

const DetailListItem = ({ detail }) => (
  <ListItem>
    <ListItemText
      primary={<Typography sx={{ textDecoration: 'underline' }}>{detail.label}</Typography>}
      secondary={detail.value}
    />
  </ListItem>
);

export default DetailListItem;