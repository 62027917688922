import React, { createContext, useState, useContext } from 'react';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor } from '../../globals.js';

// Create context
const UserContext = createContext(null);

// Create provider component
export function UserProvider({ children }) {
    const [user, setUser] = useState(null);

    console.log(user)
    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
}

// Hook for using user context
export function useUser() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}