import * as React from 'react';
import { getCurrentUser } from './firebase'; // adjust the path as needed

function PricingPage() {
  const [email, setEmail] = React.useState('');
  const [uid, setUid] = React.useState('');

  React.useEffect(async () => {
      const user = await getCurrentUser();
      if (user) {
        setEmail(user.email);
        setUid(user.uid);
      }
  }, []);

  return (
    /* <stripe-pricing-table
      pricing-table-id="prctbl_1NSsmjCNycBLDzPlySBdoJbP"
      publishable-key="pk_live_51NBs0SCNycBLDzPlvoj2f03ZKusjTQEJfIIzH7Lk5Q7YyXrbr8R0uxMcUPR4vxGbihIynaoYfSdbKnrhrwRil9ne0074oXRG33"
    > */
    <stripe-pricing-table 
      pricing-table-id="prctbl_1NsCfMCNycBLDzPlxDRqXAWE"
      publishable-key="pk_test_51NBs0SCNycBLDzPltOadINolRwo0mQS6JBryFaJkWBke9jxWsgb7uFdBSmC4rjB4B7ioHnJUZK4eFr10sdryddOf000zQ93VGL"
      customer-email={email}
      client-reference-id={uid}
    />
  );
}
 
export default PricingPage; 