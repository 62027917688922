import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';
import { styled, alpha } from '@mui/system';
import logo from '../assets/logo.png';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textGradient, accentColor } from '../globals.js';

const FooterBox = styled(Box)(({ theme }) => ({
  // backgroundColor: secondaryBackgroundColor,
  color: mainTextColor,
  padding: theme.spacing(6),
  borderTop: `2px solid ${mainTextColor}`, // Add this line to add the border to the top of the footer
}));

const StyledLink = styled(Link)({
  color: mainTextColor,
  '&:hover': {
    textDecoration: 'none',
    color: alpha(mainTextColor, 0.7), // adjust the 0.7 as needed, 1 is opaque and 0 is fully transparent
  },
});

const LogoGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const Footer = () => {
  return (
    <FooterBox sx={{ backgroundColor: mainBackgroundColor, borderTop: `2px solid ${accentColor}` }}>
      <Grid container spacing={3}>
        <LogoGrid item xs={12} sm={4}>
          <img src={logo} alt="Logo" style={{ width: '75px', marginRight: '1rem' }} />
          <Typography variant="h6" component={Link} to="/" sx={{ color: mainTextColor, flexGrow: 1, fontSize: '2.5em', textDecoration: 'none', display: 'flex', alignItems: 'center','& span': {
            background: textGradient,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }, '&:hover': { color: mainTextColor, textDecoration: 'none' } }}>
            <span>Advocate AI</span>
          </Typography>
        </LogoGrid>

        <Grid item xs={12} sm={2}>
          <Typography variant="h6" sx={{ background: textGradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Company</Typography>
          <StyledLink href="/about" underline="none">About Us</StyledLink>
          <br />
          <StyledLink href="/careers" underline="none">Careers</StyledLink>
          <br />
          <StyledLink href="/contact" underline="none">Contact Us</StyledLink>
        </Grid>

        <Grid item xs={12} sm={2}>
        <Typography variant="h6" sx={{ background: textGradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Social</Typography>
          <StyledLink href="https://www.linkedin.com/company/advocate-ai/" underline="none">LinkedIn</StyledLink>
          <br />
          <StyledLink href="https://twitter.com/Advocate_AI" underline="none">Twitter</StyledLink>
          <br />
          <StyledLink href="https://www.instagram.com/advocate-ai" underline="none">Instagram</StyledLink>
        </Grid>

        <Grid item xs={12} sm={2}>
        <Typography variant="h6" sx={{ background: textGradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Legal</Typography>
          <StyledLink href="/privacy-policy" underline="none">Privacy Policy</StyledLink>
          <br />
          <StyledLink href="/terms-of-use" underline="none">Terms of Service</StyledLink>
        </Grid>
      </Grid>
    </FooterBox>
  );
};

export default Footer;
