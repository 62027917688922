import React from 'react';
import { Box, Typography, Divider, Link, Container } from '@mui/material';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor } from '../../globals.js';

const TermsOfUse = () => {
    return (
        <Box sx={{ 
            width: '100%', 
            paddingBottom: '2%', // Adjust for footer if necessary
            backgroundColor: mainBackgroundColor, 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center' 
        }}>            
            <Container maxWidth="md" sx={{ my: 6 }}>
                <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', mt: 4, color: mainTextColor }}>
                    Terms of Use
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: mainTextColor }}>
                    Agreement to Terms
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor}}>
                    By using this website, you agree to be bound by these Terms of Use. If you do not agree to these terms, do not use our services or access our website.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: mainTextColor }}>
                    Intellectual Property Rights
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor}} >
                    Unless otherwise indicated, the website is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the website are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: mainTextColor }}>
                    User Representations
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor}}>
                    By using our services, you represent and warrant that all information you submit will be true, accurate, current, and complete and your use of the services does not violate any applicable law or regulation.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: mainTextColor }}>
                    User Responsibilities
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor}} >
                    You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: mainTextColor }}>
                    Changes to Our Terms
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: mainTextColor}}>
                    We reserve the right, in our sole discretion, to change these Terms of Use at any time. If we make changes, we will notify you by revising the updated date at the bottom of these Terms and, in some cases, we may provide you with additional notice.
                </Typography>
                <Divider variant="middle" />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' , color: mainTextColor}}>
                    Contact Us
                </Typography>
                <Typography variant="body2" paragraph sx={{ mb: 12, color: mainTextColor }}>
                    To ask questions or comment about these terms and our practices, contact us at: <Link href="mailto:ouradvocateai@gmail.com">ouradvocateai@gmail.com</Link>.
                </Typography>
            </Container>
        </Box>
    );
};

export default TermsOfUse;
