import React from 'react';
import { Box, Typography, Divider, Grid, Card, Button } from '@mui/material';
import { styled } from '@mui/system';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor, textGradient, lighterAccentColor } from '../globals.js';
import PricingPage from './PricingPage.js'

const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: accentColor,
    color: "#fff",
    padding: theme.spacing(1, 3),
    textTransform: 'none',
    "&:hover": {
      backgroundColor: "#2563EB"
    }
}));

const Pricing = () => {
    return (
        <div>
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            paddingTop: '3.5%',
            minHeight: '100vh',
            width: '100vw',
            backgroundColor: mainBackgroundColor,
            fontFamily: textFont
        }}>
            <Box sx={{ 
                flexDirection: 'column', 
                alignItems: 'center', 
                padding: '2em', 
                borderRadius: '8px',
                width: '70vw',
                backgroundColor: mainBackgroundColor,
                maxWidth: '960px' 
            }}> 
                <Typography 
                    variant="h2" 
                    gutterBottom 
                    fontWeight='700'
                    align='center' // Added this line
                    sx={{
                        fontSize: { xs: '3em', sm: '3.5em', md: '4.5em' },
                        backgroundColor: mainBackgroundColor,
                        '& span': {
                            background: textGradient,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }
                    }}
                >
                    <span>Pricing</span>
                </Typography>
                <Typography variant="h4" align="center" sx={{ color: mainTextColor, paddingBottom: '1.5em', fontWeight: 'bold' }}>Understand the legal documents in front of you</Typography>
                <PricingPage/>
            </Box>
        </Box>
        </div>

    );
};

export default Pricing;
