import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor, textGradient, lighterAccentColor } from '../../globals.js';

const Careers = () => {
    return (
        <div style={{ 
            display: 'flex',
            paddingTop: '15%',
            justifyContent: 'center', // Horizontal centering
            backgroundColor: mainBackgroundColor, 
            height: '100vh'
        }}>
            <Box sx={{ textAlign: 'center', maxWidth: '75%' }}> {/* Adjust maxWidth as needed */}
            <Typography
                            variant="h2"
                            gutterBottom
                            fontWeight='700'
                            sx={{
                                color: mainTextColor,
                                fontSize: { xs: '2.8rem', sm: '3.75rem', md: '4.5rem' }, // Scaling font size
                                '& span': {
                                    background: textGradient,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                },
                                textAlign: 'center', // Center aligned text
                            }}
                        >
                            <span>Careers</span>
                </Typography>
                <Box maxWidth="50%" mx="auto">
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mt: 4, color: mainTextColor }}>
                        Want to disrupt the legal system?
                    </Typography>
                </Box>
                <Box maxWidth="75%" mx="auto" sx={{ textAlign: 'left' }}>
                    <Typography variant="body1" paragraph sx={{ mb: 0, mt: 4, color: mainTextColor }}>
                        We aim to recruit candidates of the highest calibre and offer a variety of exciting opportunities. If you would like to find out more about our current vacancies please contact <Link href="mailto:ouradvocateai@gmail.com">ouradvocateai@gmail.com</Link>
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

export default Careers;
