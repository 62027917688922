import React from 'react';
import { Box, List, ListItemButton, ListItemText } from '@mui/material';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor } from '../globals.js';

const Sidebar = ({ selectedSection, setSelectedSection, sections }) => {
    return (
        <Box sx={{ width: '250px', minHeight: '625px', mx: 'auto', bgcolor: secondaryBackgroundColor, p: 2, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '4px' }}>
            <List component="nav">
                {sections.map(section => (
                    <ListItemButton 
                        key={section}
                        selected={selectedSection === section} 
                        onClick={() => setSelectedSection(section)}
                        sx={{ 
                            color: selectedSection === section ? mainTextColor : secondaryTextColor, 
                            fontWeight: selectedSection === section ? 'bold' : 'normal',
                            bgcolor: selectedSection === section ? secondaryBackgroundColor : 'transparent',
                            '&:hover': {
                                bgcolor: secondaryBackgroundColor,
                            },
                            p: 1,
                            borderRadius: 1,
                        }}
                    >
                        <ListItemText primary={section} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );
};

export default Sidebar;
