import React from 'react';
// import FileUpload from './FileUpload.js';
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor } from '../../../globals.js';

const Title = styled(Typography)(({ theme }) => ({
    color: mainTextColor,
    fontWeight: 'bold',
}));

const IntellectualPropertyAgreements = () => {
    return (
        <Box sx={{ width: '100%', minHeight: '100vh' }}>
            <Box sx={{ py: 7, px: 5, pb: 1 }}>
                <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', color: '#808080', '& span': { background: 'linear-gradient(90deg, #F59E0B 35%, #CC3E00 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent',}}}>
                    <span>Intellectual Property Agreements</span>
                </Typography>
                <Title variant="h5" align="center" gutterBottom sx={{ my: 2 }}>
                    You've got your ideas. They have their ideas. Let's ensure everything is settled correctly. 
                </Title>
            </Box>
            <Box sx={{ py: 1, px: 3, pb: 5 }}>
            </Box>
        </Box>
    );
}

export default IntellectualPropertyAgreements;
