import React, { useState } from 'react';
import { Avatar, Box, Typography, Grid, Card, CardMedia, CardContent, CardActions, Button, Divider, useMediaQuery, Container, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor, textGradient, lighterAccentColor } from '../globals.js';
import RequestDemo from './RequestDemo.js'
// Using Material UI icons
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HomeIcon from '@mui/icons-material/Home';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import useValidation from './Authentication/Validations';
import axios from "axios";

const Section = styled(Box)(({ theme }) => ({
    height: '100vh',
    [theme.breakpoints.down('xs')]: {
        fontSize: '2rem', // Smaller font size for extra small screens
    },
    [theme.breakpoints.down('md')]: {
        height: 'auto',
        paddingBottom: theme.spacing(4),  // Add bottom padding for screens smaller than 900px
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    font: textFont,
}));

const Title = styled(Typography)(({ theme }) => ({
    font: textFont,
    fontWeight: 'bold',
    color: mainTextColor,
    fontSize: '2rem',
    [theme.breakpoints.down('xs')]: {
        fontSize: '0.9rem', // Smaller font size for subtitles
    },
    [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(5),  // Add top padding
    },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 'lighter',
    font: textFont,
    color: mainTextColor,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),  // Add top padding
    },
}));

const HomePage = () => {
    const [emailError, setEmailError] = useState('');
    const { validateEmail } = useValidation();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [updateForm, setUpdateForm] = useState({
        email: '',
    });

    const ErrorText = styled(Typography)({
        color: "red",
        marginBottom: "10px",
        mb: 4
    });

    const SuccessText = styled(Typography)({
        color: "green",
        marginBottom: "10px",
        mb: 4
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setUpdateForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
        console.log("setting email error state");
        validateEmail(value, setEmailError);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (updateForm.email.trim() === '') {
            setEmailError("Email cannot be empty");
            return;
        }
        if (emailError == '') {
            try {
                const response = await axios.post('http://localhost:5001/upload/post-updates-email/', updateForm);
                setShowSuccessMessage(true);
                console.log(response.data); // Success message
                clearForm();
            } catch (error) {
                console.error(error);
            }
        }
        else {
            console.log("There was an email error");
        }
    };

    const clearForm = () => {
        setUpdateForm({
            email: '',
        });
    };

    const theme = createTheme({
        typography: {
            fontFamily: textFont,
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1250,
                lg: 1410,
                xl: 1920,
            },
        },
    });

    const textGradientStyle = {
        background: textGradient,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    };    

    const products = [
        { title: "Employment Contract Analysis", description: "Analyze your employment contracts or offer letters with us. We ensure that everything is fair and square for both parties. Don't know what to ask? We got you covered.", icon: <BusinessCenterIcon />, link: "/employment-contracts" },
        { title: "Lease Agreement Analysis", description: "Navigate your rental agreement effortlessly with our comprehensive analysis. Learn about important clauses and your rights. Need advice on breaking your lease? We're here to help.", icon: <HomeIcon />, link: "/lease-agreements" },
        { title: "Prenup Analysis", description: "Ensure clarity and peace of mind before signing a prenup. It's more than just a contract, it's one of life's biggest decisions. Let us help you understand the fine print.", icon: <FamilyRestroomIcon />, link: "/prenup-agreements" }
    ];

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const glowEffect = {
        color: '#FFF', // Adjust if needed
        textShadow: `
            0 0 3px #FF4500, 
            0 0 5px #FF4500, 
            0 0 8px #FFD700
        `, // Subtle glowing effect transitioning from orange-red to gold
    };
    

    return (
        <Box sx={{ backgroundColor: mainBackgroundColor }}>
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        height: '100vh', // Height adjustment
                        alignItems: 'center',
                        justifyContent: 'center',
                        px: '10%', // Padding on the X axis (left and right)
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center', // Changed to center for all screen sizes
                            width: { xs: '100%', md: '100%' }, // Adjust width for each screen size
                            boxSizing: 'border-box',
                            paddingRight: { xs: '0', md: '0%' }, // Adjust paddingRight
                        }}
                    >
                        <Typography
                            variant="h2"
                            gutterBottom
                            fontWeight='700'
                            sx={{
                                color: mainTextColor,
                                fontSize: { xs: '2.8rem', sm: '3.75rem', md: '4.5rem' }, // Scaling font size
                                '& span': {
                                    background: textGradient,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                },
                                textAlign: 'center', // Center aligned text
                            }}
                        >
                            <span>Understand</span> Your <br />Legal and Professional Documents
                        </Typography>
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                color: mainTextColor,
                                paddingBottom: '2em',
                                fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem' },
                                width: { xs: '90%', sm: '90%', md: '80%' }, // Making the width 10% smaller
                                textAlign: 'center', // Center aligned text
                            }}
                        >
                            An advanced AI toolkit designed to proactively surface potential risks within your legal documents, keeping you fully informed, safe and ready to take action.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: 2,
                                width: '100%',
                                justifyContent: 'center', // Center aligned buttons
                                paddingTop: '2em',
                            }}
                        >
                            <Button
                                variant="contained"
                                component={Link}
                                to="/employment-contracts"
                                sx={{
                                    backgroundColor: accentColor,
                                    padding: { xs: '10px 20px', sm: '10px 40px' },
                                    textTransform: 'none',
                                    fontWeight: 900,
                                    fontSize: { xs: '0.875rem', sm: '1rem' }, // Adjust this as needed to prevent wrapping
                                    '&:hover': { backgroundColor: accentColor, color: mainTextColor },
                                    width: { xs: 'auto', sm: '25%' }, // Ensure the button is wide enough for your text
                                    whiteSpace: 'nowrap', // Prevent text wrapping
                                    minWidth: '0', // Override default minWidth if necessary
                                }}
                            >
                                Try Advocate Free
                            </Button>

                            <Button
                                variant="outlined"
                                onClick={handleClickOpen}
                                sx={{
                                    borderColor: accentColor,
                                    borderWidth: '2px', // Increase the border width to 2px
                                    color: accentColor,
                                    padding: { xs: '10px 20px', sm: '10px 40px' },
                                    textTransform: 'none',
                                    fontWeight: 900,
                                    fontSize: { xs: '0.875rem', sm: '1rem' },
                                    '&:hover': { backgroundColor: accentColor, borderColor: accentColor, color: mainBackgroundColor, borderWidth: '2px'},
                                    width: { xs: 'auto', sm: '25%' }, // Auto width on xs, fit-content on sm and larger
                                    whiteSpace: 'nowrap', // Prevent text wrapping
                                    minWidth: '0', // Override default minWidth if necessary
                                }}
                            >
                                Book Demo
                            </Button>
                        </Box>
                    </Box>
                    {/* Additional content */}
                </Box>
            </ThemeProvider>

            <Section bgcolor={secondaryBackgroundColor}>
                <Container>
                    <Box sx={{ textAlign: 'center', width: '100%', maxWidth: '800px', margin: '0 auto' }}>
                        <Title sx={{ fontWeight: 'bolder', fontSize: '3.5rem', '& span': { background: textGradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', } }} gutterBottom >
                            How it <span>Works</span>
                        </Title>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'space-between', flexWrap: 'wrap', padding: '20px' }}>
                        <Box sx={{
                            border: '4px solid',
                            borderColor: accentColor,
                            borderRadius: '5px',
                            padding: '20px',
                            margin: '10px',
                            flexGrow: 1,
                            flexBasis: ['100%', '23%'],
                        }}>
                            <Title sx={{ fontWeight: 'bolder', fontSize: '3.5rem' }}>1</Title>
                            <Box sx={{ height: "150px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img style={{  maxWidth: "100%", maxHeight: "100%" }} src="https://res.cloudinary.com/dko9vskvn/image/upload/v1689213723/uploadPDF_jkxnqe.png" alt="Simple Experience" />
                            </Box>
                            <Title variant="h6">Upload</Title>
                            <SubTitle sx={{ color: mainTextColor }} variant="body1">Upload your legal document. See what kinds of contracts <Link to="/products-page" style={{ color: mainTextColor, textDecoration: 'none' }}>→</Link></SubTitle>
                        </Box>
                        <Box sx={{
                            border: '4px solid',
                            borderColor: accentColor,
                            borderRadius: '5px',
                            padding: '20px',
                            margin: '10px',
                            flexGrow: 1,
                            flexBasis: ['100%', '23%'],
                        }}>
                            <Title sx={{ fontWeight: 'bolder', fontSize: '3.5rem' }}>2</Title>
                            <Box sx={{ height: "150px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img style={{  maxWidth: "90%", maxHeight: "90%" }} src="https://res.cloudinary.com/dko9vskvn/image/upload/v1688796575/chatGPT_xcb3wb.png" alt="Accurate analysis" />
                            </Box>
                            <Title variant="h6">Analyze</Title>
                            <SubTitle sx={{ color: mainTextColor }} variant="body1">We analyze your legal document using Large Language Models (LLMs). Read more about our advanced AI toolkit <Link to="/resources" style={{ color: mainTextColor, textDecoration: 'none' }}>→</Link></SubTitle>
                        </Box>
                        <Box sx={{
                            border: '4px solid',
                            borderColor: accentColor,
                            borderRadius: '5px',
                            padding: '20px',
                            margin: '10px',
                            flexGrow: 1,
                            flexBasis: ['100%', '23%'],
                        }}>
                            <Title sx={{ fontWeight: 'bolder', fontSize: '3.5rem' }}>3</Title>
                            <Box sx={{ height: "150px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img style={{  maxWidth: "80%", maxHeight: "80%" }} src="https://res.cloudinary.com/dko9vskvn/image/upload/v1689213513/downloadPDF_l3hlwg.png" alt="Key insights" />
                            </Box>
                            <Title variant="h6">Save</Title>
                            <SubTitle sx={{ color: mainTextColor }} variant="body1">You download your results for review, easily accessible for future reference.</SubTitle>
                        </Box>
                    </Box>
                </Container>
            </Section>

            <Section bgcolor={mainBackgroundColor}>
                <Container>
                    <Box sx={{ textAlign: 'center', width: '100%', maxWidth: '800px', margin: '0 auto' }}>
                        <Title sx={{ fontWeight: 'bolder', fontSize: '3.5rem', paddingBottom: '1rem' }} gutterBottom>
                            Advocate is your legal analysis tool.
                        </Title>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'space-between', flexWrap: 'wrap', padding: '20px' }}>
                        <Box sx={{
                            border: '4px solid',
                            borderColor: accentColor,
                            borderRadius: '5px',
                            padding: '20px',
                            margin: '10px',
                            flexGrow: 1,
                            flexBasis: ['100%', '23%'],
                        }}>
                            <Title sx={{ fontWeight: 'bolder', fontSize: '3.5rem' }}>1</Title>
                            <Box sx={{ height: "150px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img style={{ maxWidth: "100%", maxHeight: "100%" }} src="https://res.cloudinary.com/dko9vskvn/image/upload/v1686543002/accurate_gwvwpe.png" alt="Accurate analysis" />
                            </Box>
                            <Title variant="h6">Reliable</Title>
                            <SubTitle sx={{ color: mainTextColor }} variant="body1">Our advanced AI toolkit is built on top of best-in-class automation technology. You can rely on our service to be seamless, accurate, and comprehensive – giving time and energy back to you. Learn about our technology <Link to="/resources" style={{ color: mainTextColor, textDecoration: 'none' }}>→</Link></SubTitle>
                        </Box>
                        <Box sx={{
                            border: '4px solid',
                            borderColor: accentColor,
                            borderRadius: '5px',
                            padding: '20px',
                            margin: '10px',
                            flexGrow: 1,
                            flexBasis: ['100%', '23%'],
                        }}>
                            <Title sx={{ fontWeight: 'bolder', fontSize: '3.5rem' }}>2</Title>
                            <Box sx={{ height: "150px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img style={{  maxWidth: "90%", maxHeight: "90%" }} src="https://res.cloudinary.com/dko9vskvn/image/upload/v1686543344/insights_ukswfw.png" alt="Key insights" />
                            </Box>
                            <Title variant="h6">Trustworthy</Title>
                            <SubTitle sx={{ color: mainTextColor }} variant="body1">You’re trusting us to read and analyze your legal documents. We promise your information is safe with us. Read our privacy policy <Link to="/privacy-policy" style={{ color: mainTextColor, textDecoration: 'none' }}>→</Link></SubTitle>
                        </Box>
                        <Box sx={{
                            border: '4px solid',
                            borderColor: accentColor,
                            borderRadius: '5px',
                            padding: '20px',
                            margin: '10px',
                            flexGrow: 1,
                            flexBasis: ['100%', '23%'],
                        }}>
                            <Title sx={{ fontWeight: 'bolder', fontSize: '3.5rem' }}>3</Title>
                            <Box sx={{ height: "150px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img style={{ maxWidth: "80%", maxHeight: "80%" }} src="https://res.cloudinary.com/dko9vskvn/image/upload/v1690006762/noun-secure-5812438_k2lq2w.png" alt="Simple Experience" />
                            </Box>
                            <Title variant="h6">Secure</Title>
                            <SubTitle sx={{ color: mainTextColor }} variant="body1">You deserve to feel safe and secure in your workplace, home, and relationships. If you’re intimidated by a contract, or want to make sure you didn’t miss anything important, Advocate has your back. View our products <Link to="/employment-contracts" style={{ color: mainTextColor, textDecoration: 'none' }}>→</Link> </SubTitle>
                        </Box>
                    </Box>
                </Container>
            </Section>

            <Section bgcolor={secondaryBackgroundColor}>
                <Container maxWidth="lg" sx={{ px: 2 }}>
                    <Box sx={{ color: mainTextColor, textAlign: 'center' }}>
                        <Title sx={{ fontWeight: 'bolder', fontSize: '3.5rem', paddingBottom: '1rem' }} gutterBottom>
                            Products
                        </Title>
                    </Box>

                    {products.map((product, index) => (
                        <Card key={index} sx={{ mb: 4, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'space-between', p: 3, backgroundColor: mainBackgroundColor }}>
                            <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', color: mainTextColor }}>
                                    {product.title}
                                </Typography>
                                <Typography variant="body1" sx={{ color: mainTextColor, my: 1 }}>
                                    {product.description}
                                </Typography>
                                <Button
                                    component={Link}
                                    to={product.link}
                                    variant="contained"
                                    sx={{
                                        mt: 2,
                                        backgroundColor: accentColor,
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: lighterAccentColor,
                                            boxShadow: 'none',
                                        },
                                        textDecoration: 'none',
                                        color: 'white'
                                    }}
                                >
                                    Learn More
                                </Button>
                            </Box>
                            <Avatar sx={{ my: 2, bgcolor: 'transparent', color: accentColor }}>
                                {product.icon}
                            </Avatar>
                        </Card>
                    ))}
                </Container>
            </Section>


            <Section sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Vertically center content in the section
                alignItems: 'center', // Horizontally center content
                height: '50vh', // Use minHeight for better responsiveness
                paddingY: { xs: 4, sm: 6 }, // Responsive vertical padding
            }}>
                <Container>
                    <Box sx={{ textAlign: 'center', width: '100%', maxWidth: { sm: '600px', md: '800px' }, margin: '0 auto' }}>
                        <Title sx={{
                            fontWeight: 'bolder',
                            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }, // Responsive font size
                            lineHeight: { xs: '2.25rem', sm: '2.5rem', md: '2.75rem' }, // Responsive line height
                            color: mainTextColor, // Consider using a dark grey instead of black for a softer look
                            marginY: 3, // Adds margin top and bottom for spacing
                            marginX: 1
                        }}>
                            Spend more time on what matters most and less time reading legal documents.
                        </Title>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: accentColor,
                                textTransform: 'none',
                                padding: '10px 20px', // Slightly reduced padding for a more refined look
                                fontSize: '1rem', // Standard font size for button
                                borderRadius: '10px', // Slightly reduced corner radius for a modern look
                                transition: 'background-color 0.3s ease', // Smooth background color transition
                                '&:hover': {
                                    backgroundColor: lighterAccentColor, // Softer hover color effect
                                },
                                textDecoration: 'none',
                                color: 'white', // Ensure text color is white for better contrast
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Subtle shadow for depth
                                marginY: 2, // Adds margin top and bottom for spacing
                            }}
                        >
                            Try Advocate Today
                        </Button>
                    </Box>
                </Container>
            </Section>

            <Section sx={{ height: '35vh', backgroundColor: secondaryBackgroundColor }}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ py: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <Typography variant="h4" align="center" fontWeight="bold" gutterBottom color={mainTextColor}>
                            Sign Up For Updates
                        </Typography>
                        <Typography variant="body1" align="center" gutterBottom color={mainTextColor}>
                            We wouldn't dream of spamming you or selling your info.
                        </Typography>
                        <TextField
                            fullWidth
                            label="Enter your email address"
                            variant="outlined"
                            name="email"
                            sx={{
                                mt: 2, mb: 1, width: '52%'
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mainTextColor
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: 'white' // This sets the text color to white
                                }
                            }}
                            value={updateForm.email}
                            onChange={handleInputChange}
                            onBlur={handleInputChange}
                        />
                        {emailError && <ErrorText style={{ fontSize: '12px', textAlign: 'left' }}>{emailError}</ErrorText>}
                        {showSuccessMessage && <SuccessText style={{ fontSize: '12px', textAlign: 'left' }}>Form submitted successfully!</SuccessText>}
                        <Button variant="contained" sx={{ backgroundColor: accentColor, '&:hover': { backgroundColor: lighterAccentColor } }} type="submit">
                            Let's Go
                        </Button>
                    </Box>
                </form>
            </Section>
        </Box>
    );
};

export default HomePage;
