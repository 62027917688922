import React, { useState, useEffect } from 'react'; // import useEffect here
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Card, List, ListItem, Paper, Button, Grid, Input, Container } from '@mui/material';
import UploadBox from '../Shared/UploadBox.js';
import DetailListItem from '../Shared/DetailListItem.js';

const LeaseUpload = () => {
    const [fileInfo, setFileInfo] = useState([]);
    const [wordCount, setWordCount] = useState(null);
    const [rentCost, setRentCost] = useState(null);
    const [breakingLease, setBreakLeaseInfo] = useState(null);
    const [nonTenantAccess, setNonTenantAccess] = useState(null);
    const [guestRules, setGuestRules] = useState(null);
    const [rentPayment, setRentPayment] = useState(null);
    const [rentDueDate, setRentDueDate] = useState(null);
    const [rentersInsurance, setRentersInsurance] = useState(null);
    const [renewalProcess, setRenewalProcess] = useState(null);
    const [petPolicy, setPetPolicy] = useState(null);
    const [maintenceRequests, setMaintenceRequests] = useState(null);
    const [securityDeposit, setSecurityDeposit] = useState(null);
    const [securityDepositRefund, setSecurityDepositRefund] = useState(null);
    const [moveInFees, setMoveInFees] = useState(null);
    const [lengthOfLease, setLengthOfLease] = useState(null);
    const [movingOutNotice, setMovingOutNotice] = useState(null);
    const [subleaseTerms, setSubleaseTerms] = useState(null);
    const [isAbleToScan, setIsAbleToScan] = useState(false);
    const isMounted = React.useRef(true);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        // On component unmount, change isMounted to false
        setIsAbleToScan(true);
        return () => {
            isMounted.current = false;
        }
    }, []);

    const onDrop = async acceptedFiles => {
        setIsLoading(true);
        console.log("HEY 1");
        if (isMounted.current) setFileInfo(acceptedFiles);

        for (const file of acceptedFiles) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                console.log("HEY");
                const backendUrl = process.env.REACT_APP_BACKEND_URL; // Accessing the environment variable
                const response = await axios.post(`${backendUrl}/upload/lease-upload/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log(response.data);

                if (isMounted.current) {
                    setWordCount(response.data.word_count);
                    setRentCost(response.data.rent_cost);
                    setBreakLeaseInfo(response.data.breaking_lease);
                    setNonTenantAccess(response.data.non_tenant_access);
                    setGuestRules(response.data.guest_rules);
                    setRentPayment(response.data.rent_payment);
                    setRentDueDate(response.data.rent_due_date);
                    setRentersInsurance(response.data.renters_insurance);
                    setRenewalProcess(response.data.renewal_process);
                    setPetPolicy(response.data.pet_policy);
                    setMaintenceRequests(response.data.maintence_requests);
                    setSecurityDeposit(response.data.security_deposit);
                    setSecurityDepositRefund(response.data.security_deposit_refund);
                    setMoveInFees(response.data.move_in_fees);
                    setLengthOfLease(response.data.length_of_lease);
                    setMovingOutNotice(response.data.moving_out_notice);
                    setSubleaseTerms(response.data.sublease_terms);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error uploading file:', error.response ? error.response : error);
            }
        }
    };

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: 'application/pdf',
    });

    const details = [
        { label: 'Rent Cost', value: rentCost },
        { label: 'Rent Payment', value: rentPayment },
        { label: 'Rent Due Date', value: rentDueDate },
        { label: 'Security Deposit', value: securityDeposit },
        { label: 'Security Deposit Refund', value: securityDepositRefund },
        { label: 'Move In Fees', value: moveInFees },
        { label: 'Length of Lease', value: lengthOfLease },
        { label: 'Moving Out Notice', value: movingOutNotice },
        { label: 'Breaking Lease', value: breakingLease },
        { label: 'Non Tenant Access', value: nonTenantAccess },
        { label: 'Sublease Terms', value: subleaseTerms },
        { label: 'Guest Rules', value: guestRules },
        { label: 'Renters Insurance', value: rentersInsurance },
        { label: 'Renewal Process', value: renewalProcess },
        { label: 'Pet Policy', value: petPolicy },
        { label: 'Maintence Requests', value: maintenceRequests },
        { label: 'Word Count', value: wordCount },
    ];

    return (
        <Container maxWidth="lg">
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
                <UploadBox
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    isAbleToScan={isAbleToScan}
                    fileInfo={fileInfo}
                    isLoading={isLoading}
                    getInputProps={getInputProps}
                    contractType="Lease Agreement"
                />
                {breakingLease !== null && guestRules !== null && rentPayment !== null && (
                    <Grid item xs={12}>
                        <Card sx={{ padding: 2, marginBottom: 2 }}>
                            <Typography variant="h6" gutterBottom>Lease Agreement Details:</Typography>
                            <List>
                                {details.map((detail, index) => (
                                    <DetailListItem key={index} detail={detail} />
                                ))}
                            </List>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default LeaseUpload;
