import { Menu, MenuItem, Typography, Divider, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor, textGradient } from '../../globals.js';

const UserMenu = ({ user, handleUserMenuClose, userMenuOpen, userAnchorEl, handleSignOut }) => (
  <Menu
    id="user-menu"
    anchorEl={userAnchorEl}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    keepMounted
    open={userMenuOpen}
    onClose={handleUserMenuClose}
  >
    <MenuItem disabled>
      <Typography sx={{ color: mainTextColor, fontSize: '1.25em', fontWeight: 700 }}>{user.email}</Typography>
    </MenuItem>
    <Divider sx={{ width: '100%', alignSelf: 'center', marginY: 1, borderColor: 'black', borderWidth: 1, color: secondaryBackgroundColor }} /> {/* adjust width, color and thickness */}
      <MenuItem onClick={handleUserMenuClose} component={Link} to="/settings">
        <ListItemText primary="Manage Account" />
      </MenuItem>
      <MenuItem onClick={handleUserMenuClose} component={Link} to="/contact">
        <ListItemText primary="Help" />
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <ListItemText primary="Log Out" />
      </MenuItem>
  </Menu>
);

export default UserMenu;
