import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from './components/NavBar';  // import the NavBar component
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EmploymentContracts from './components/ProductPages/Professional/EmploymentContracts';
import LeaseAgreements from './components/ProductPages/RealEstate/LeaseAgreements';
import PrenupAgreements from './components/ProductPages/Personal/PrenupAgreements';
import SeparationAgreements from './components/ProductPages/Professional/SeparationAgreements';
import HomePage from './components/HomePage';
import About from './components/Company/About';
import Contact from './components/Company/Contact';
import Careers from './components/Company/Careers';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import Footer from './components/Footer';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import TermsOfUse from './components/Legal/TermsOfUse';
import Settings from './components/Settings';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Pricing from './components/Pricing';
import ProductsPage from './components/ProductsPage';
import Resources from './components/Company/Resources';
import { UserProvider } from './components/Authentication/UserContext';
import AuthWrapper from './components/Authentication/AuthWrapper';
import LogIn from "./components/Authentication/LogIn";
import IntellectualPropertyAgreements from "./components/ProductPages/Professional/IntellectualPropertyAgreements";
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont } from './globals.js';

const theme = createTheme({
  typography: {
    fontFamily: textFont,
    color: mainBackgroundColor
    // color: '#211522',
  },
});

function App() {
  return (
    <UserProvider >
      <GoogleReCaptchaProvider reCaptchaKey="6Lfnm3YmAAAAAJex3LAPUrPjHmw3sSH1OVTGOYSD">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <NavBar />
            <Routes>
              <Route path="/employment-contracts" element={
                <AuthWrapper>
                  <EmploymentContracts />
                </AuthWrapper>} />
              <Route path="/separation-agreements" element={
                <AuthWrapper>
                  <SeparationAgreements />
                </AuthWrapper>} />
              <Route path="/ip-agreements" element={
                <AuthWrapper>
                  <IntellectualPropertyAgreements />
                </AuthWrapper>} />
              <Route path="/lease-agreements" element={
                <AuthWrapper>
                  <LeaseAgreements />
                </AuthWrapper>
              } />
              <Route path="/prenup-agreements" element={
                <AuthWrapper>
                  <PrenupAgreements />
                </AuthWrapper>
              } />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/settings" element={
                <AuthWrapper>
                  <Settings />
                </AuthWrapper>} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/products-page" element={<ProductsPage />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/login" element={<><LogIn /> <HomePage /></>} />
            </Routes>
            <Footer />
          </Router>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </UserProvider>
  );
}

export default App;
