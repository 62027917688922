import * as React from 'react';
import { Send } from '@mui/icons-material';
import { Box, Button, Container, Card, CardContent, Divider, FormControl, InputLabel, InputAdornment, MenuItem, Select, TextField, Typography, Avatar } from '@mui/material';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor } from '../globals.js';


const BillingContent = () => {
    return (
        <Box sx={{ width: '100%' }}>
            {/* Upcoming Charges Card */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
                <Card sx={{ m: 1, flex: '1 1 auto', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                    <CardContent>
                        <Typography variant="h6" color="textSecondary">Upcoming Charges</Typography>
                        <Typography variant="h4" sx={{ my: 2 }}>$17.99</Typography>
                        <Button variant="outlined" size="small">VIEW CHARGES</Button>
                        <Divider sx={{ my: 2 }} />
                        <Typography color="textSecondary">Billing History</Typography>
                        <Typography>Last payment on: 11/05/23</Typography>
                        <Button variant="outlined" size="small" sx={{ mt: 1 }}>VIEW HISTORY</Button>
                    </CardContent>
                </Card>

                {/* Payment Information Card */}
                <Card sx={{ m: 1, flex: '1 1 auto', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                    <CardContent>
                        <Typography variant="h6" color="textSecondary" gutterBottom>Payment Information</Typography>
                        <Typography gutterBottom>Payment Method</Typography>
                        <Button variant="outlined" size="small" sx={{ mb: 2 }}>UPDATE PAYMENT</Button>
                        <Typography>VISA: •••• •••• •••• 5631</Typography>
                        <Divider sx={{ my: 2 }} />
                        <TextField
                            label="Gift or Promotional Code"
                            variant="outlined"
                            size="small"
                            sx={{ mt: 2, mb: 1 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button>
                                            <Send />
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </CardContent>
                </Card>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {/* Your Subscription Card */}
                <Card sx={{ m: 1, flex: '1 1 auto', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                    <CardContent>
                        <Typography variant="h6" color="textSecondary">Your Subscription</Typography>
                        <Typography sx={{ my: 1 }}>Base Plan</Typography>
                        <Button variant="outlined" size="small" sx={{ mb: 2 }}>MANAGE PLAN</Button>
                        <Divider sx={{ my: 1 }} />
                        <Typography color="textSecondary">Add-ons</Typography>
                        <Typography>None Added</Typography>
                        <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }}>MANAGE ADD-ONS</Button>
                        <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }}>PAUSE</Button>
                        <Button variant="outlined" size="small" sx={{ mt: 1 }}>CANCEL</Button>
                    </CardContent>

                </Card>
            </Box>
        </Box>
    );
}
{/* <div>
                <form method="POST" action="/create-customer-portal-session">
                    <button type="submit">Manage billing</button>
                </form>
            </div> */}

export default BillingContent;
