import React, { useEffect, useState } from 'react';
import { useUser } from './UserContext'; // Use this import instead
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import LogIn from './LogIn';
import HomePage from '../HomePage';

const AuthWrapper = ({ children }) => {
    const { user } = useUser(); // Use the custom hook here
    const [openLogInDialog, setOpenLogInDialog] = useState(false);
    const navigate = useNavigate(); // Initialize the useNavigate hook

    useEffect(() => {
        if (!user) {
            setOpenLogInDialog(true);
        }
        else {
            setOpenLogInDialog(false);
        }
    }, [user]);

    const handleCloseLogInDialog = () => {
        setOpenLogInDialog(false);
        navigate('/'); // Redirect to the '/' homepage when the dialog is closed
    };

    return (
        <>
            {user ? children : <HomePage />}
            {!user && <LogIn isOpen={openLogInDialog} onClose={handleCloseLogInDialog} />}
        </>
    );
};

export default AuthWrapper;