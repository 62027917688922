// // Lavender, orchid, black, white theme
// export const mainBackgroundColor = '#211522'; // Black
// export const secondaryBackgroundColor = '#613659'; // Orchid
// export const mainTextColor = "#FFFFFF"; // White
// export const secondaryTextColor = "#0D4F8B"; // Blue
// export const accentColor = '#C197D2'; // Lavender
// export const lighterAccentColor = '#ebdef1' // Lighter Lavender
// export const textFont = "Roboto";
// export const textGradient = 'linear-gradient(90deg, #D3B1C2 35%, #C197D2 100%)'


// Dark theme with red, orange, and yellow accents
// export const mainBackgroundColor = '#000000'; // Black
// export const secondaryBackgroundColor = '#333333'; // Dark Grey
// export const mainTextColor = "#FFFFFF"; // White
// export const secondaryTextColor = "#FF4500"; // Orange Red
// export const accentColor = '#FF8C00'; // Dark Orange
// export const lighterAccentColor = '#FFD700' // Gold
// export const textFont = "'TT Commons Pro', sans-serif";
// export const textGradient = 'linear-gradient(90deg, #FF4500 35%, #FFD700 100%)' // Gradient from orange red to gold

// White, orange, gray theme
// export const mainBackgroundColor = '#FFFFFF'; // White
// export const secondaryBackgroundColor = '#F1F5F9'; // Light Gray
// export const mainTextColor = "#111827"; // Dark Blue
// export const secondaryTextColor = "#0D4F8B"; // Blue
// export const accentColor = '#F59E0B'; // Bright Orange
// export const textFont = "Roboto";
// export const textGradient = 'linear-gradient(90deg, #F59E0B 35%, #CC3E00 100%)'
// export const lighterAccentColor = 'rgba(255, 165, 0, 0.9)' // Lighter Orange

// blue
// export const mainBackgroundColor = '#FFFFFF'; // White
// export const secondaryBackgroundColor = '#F8F9FA'; // Light Gray
// export const mainTextColor = "#212529"; // Dark Gray
// export const secondaryTextColor = "#6C757D"; // Gray
// export const accentColor = '#007BFF'; // Blue
// export const lighterAccentColor = '#17A2B8' // Light Blue
// export const textFont = "Inter, sans-serif";
// export const textGradient = 'linear-gradient(90deg, #007BFF 35%, #17A2B8 100%)' // Gradient from blue to light blue

export const mainBackgroundColor = '#FFFFFF'; // White for main background
export const secondaryBackgroundColor = '#F5F5F5'; // Light gray for secondary background
export const mainTextColor = "#333333"; // Dark gray for main text
export const secondaryTextColor = "#666666"; // Medium gray for secondary text
export const accentColor = '#2E8B57'; // Sea green for accent
export const lighterAccentColor = '#EEEEEE'; // Lighter gray for lighter accent
export const darkerAccentColor = '#212121'; // Very dark gray for darker accent
export const textFont = "Inter, sans-serif";
export const textGradient = 'linear-gradient(90deg, #2E8B57 20%, #3CB371 80%)'; // Gradient from sea green to medium sea green