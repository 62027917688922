import React, { useState } from 'react';
import { Container, Typography, TextField, Box, Button, Grid, Link } from '@mui/material';
import { styled } from '@mui/system';
import axios from "axios";
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor, textGradient, lighterAccentColor } from '../../globals.js';
import useValidation from '../Authentication/Validations';

const StyledContainer = styled(Container)(({ theme }) => ({
  height: 'calc(100vh - 64px)', // Deducting navbar height
  // marginTop: '2rem',
  paddingTop: '3%',
  color: mainTextColor,
  fontFamily: textFont,
}));

const ContactUs = () => {
  const { firstNameError, validateFirstName } = useValidation();
  const { lastNameError, validateLastName } = useValidation();
  const { emailError, validateEmail } = useValidation();
  const { subjectError, validateSubject } = useValidation();
  const { messageError, validateMessage } = useValidation();

  const [contactForm, setContactForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: ''
});

  const firstNameChecker = () => {
    validateFirstName(contactForm.firstName);
  }

  const lastNameChecker = () => {
    validateLastName(contactForm.lastName);
  }

  const emailChecker = () => {
    validateEmail(contactForm.email);
  }

  const subjectChecker = () => {
    validateSubject(contactForm.subject);
  }

  const messageChecker = () => {
    validateMessage(contactForm.message);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContactForm(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: mainTextColor, // Default border color
      },
      '&:hover fieldset': {
        borderColor: mainTextColor, // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: mainTextColor, // Border color when the text field is focused
      },
    },
    '& .MuiInputLabel-root': {
      color: mainTextColor, // Default label color
      '&.Mui-focused': {
        color: mainTextColor, // Label color when the text field is focused
      }
    }
  });
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Here, you would typically send the form data to your backend API
    console.log(contactForm);

    try {
      const response = await axios.post('http://localhost:5001/upload/send-email/', contactForm);
      console.log(response.data); // Success message
    } catch (error) {
      console.error(error);
    }
  };

  const ErrorText = styled(Typography)({
    color: "red",
    marginBottom: "10px",
    mb: 4
  });

  return (
    <Box sx={{ backgroundColor: mainBackgroundColor, alignItems: 'center', justifyContent: 'center' }}>
      <StyledContainer maxWidth="lg" sx={{ paddingTop: '10%'}} >
      <Typography
                            variant="h2"
                            gutterBottom
                            fontWeight='700'
                            sx={{
                                color: mainTextColor,
                                fontSize: { xs: '2.8rem', sm: '3.75rem', md: '4.5rem' }, // Scaling font size
                                '& span': {
                                    background: textGradient,
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                },
                                textAlign: 'center', // Center aligned text
                            }}
                        >
                            <span>Questions?</span> Let's Chat
                </Typography>

        <Typography variant="h5" align="center">
          We'd love to hear from you! Whether you have a question about Advocate AI, need assistance or just want to talk, please feel free to reach out.
        </Typography>

        <Box component="form" my={4} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {firstNameError ? ( // Conditionally render the error text or the label
                <TextField
                  fullWidth
                  label={<ErrorText>{firstNameError}</ErrorText>}
                  variant="outlined"
                  name="firstName"
                  value={contactForm.firstName}
                  onChange={handleInputChange}
                  onBlur={firstNameChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  error // Set the 'error' prop to true when there's an error
                />
              ) : (
                <CustomTextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  value={contactForm.firstName}
                  onChange={handleInputChange}
                  onBlur={firstNameChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  InputProps={{
                    style: {
                        color: mainTextColor // This sets the text color to white
                    }
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              {lastNameError ? ( // Conditionally render the error text or the label
                <CustomTextField
                  fullWidth
                  label={<ErrorText>{lastNameError}</ErrorText>}
                  variant="outlined"
                  name="lastName"
                  value={contactForm.lastName}
                  onChange={handleInputChange}
                  onBlur={lastNameChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  InputProps={{
                    style: {
                        color: mainTextColor // This sets the text color to white
                    }
                  }}
                  error // Set the 'error' prop to true when there's an error
                />
              ) : (
                <CustomTextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  value={contactForm.lastName}
                  onChange={handleInputChange}
                  onBlur={lastNameChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  InputProps={{
                    style: {
                        color: mainTextColor // This sets the text color to white
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {emailError ? ( // Conditionally render the error text or the label
                <CustomTextField
                  fullWidth
                  label={<ErrorText>{emailError}</ErrorText>}
                  variant="outlined"
                  name="email"
                  value={contactForm.email}
                  onChange={handleInputChange}
                  onBlur={emailChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  InputProps={{
                    style: {
                        color: mainTextColor // This sets the text color to white
                    }
                  }}
                  error // Set the 'error' prop to true when there's an error
                />
              ) : (
                <CustomTextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={contactForm.email}
                  onChange={handleInputChange}
                  onBlur={emailChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  InputProps={{
                    style: {
                        color: mainTextColor // This sets the text color to white
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {subjectError ? ( // Conditionally render the error text or the label
                <CustomTextField
                  fullWidth
                  label={<ErrorText>{subjectError}</ErrorText>}
                  variant="outlined"
                  name="subject"
                  value={contactForm.subject}
                  onChange={handleInputChange}
                  onBlur={subjectChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  InputProps={{
                    style: {
                        color: mainTextColor // This sets the text color to white
                    }
                  }}
                  error // Set the 'error' prop to true when there's an error
                />
              ) : (
                <CustomTextField
                  fullWidth
                  label="Subject"
                  variant="outlined"
                  name="subject"
                  value={contactForm.subject}
                  onChange={handleInputChange}
                  onBlur={subjectChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  InputProps={{
                    style: {
                        color: mainTextColor // This sets the text color to white
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {messageError ? ( // Conditionally render the error text or the label
                <CustomTextField
                  fullWidth
                  label={<ErrorText>{messageError}</ErrorText>}
                  variant="outlined"
                  name="message"
                  value={contactForm.message}
                  onChange={handleInputChange}
                  onBlur={messageChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  InputProps={{
                    style: {
                        color: mainTextColor // This sets the text color to white
                    }
                  }}
                  error // Set the 'error' prop to true when there's an error
                />
              ) : (
                <CustomTextField
                  fullWidth
                  label="How can we help?"
                  variant="outlined"
                  name="message"
                  value={contactForm.message}
                  onChange={handleInputChange}
                  onBlur={messageChecker}
                  InputLabelProps={{
                    style: {
                      color: mainTextColor
                    }
                  }}
                  InputProps={{
                    style: {
                        color: mainTextColor // This sets the text color to white
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" sx={{ backgroundColor: accentColor, '&:hover': { backgroundColor: lighterAccentColor } }} type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* <Box mt={6}>
          <Typography variant="h5">Our Contact Information</Typography>
          <Typography>Email: ouradvocateai@google.com</Typography>
          <Typography>Phone: (123) 456-7890</Typography>
          <Typography>Address: 533 Filbert St, San Francisco, CA, USA</Typography>
        </Box>

        <Box mt={3} mb={6}>
          <Typography variant="h6">Follow Us</Typography>
          <Typography>You can also reach out to us on our social media channels:</Typography>
          <Typography>
            <Link href="https://www.linkedin.com/company/advocate-ai/" target="_blank" rel="noopener noreferrer" underline="none" sx={{ color: mainTextColor }}>LinkedIn</Link> |
            <Link href="https://twitter.com/advocate_ai" target="_blank" rel="noopener noreferrer" underline="none" sx={{ color: mainTextColor }}> Twitter</Link> |
            <Link href="https://www.instagram.com/advocate_ai/" target="_blank" rel="noopener noreferrer" underline="none" sx={{ color: mainTextColor }}> Instagram</Link>
          </Typography>
        </Box> */}

      </StyledContainer>
    </Box>
  );
};

export default ContactUs;
