import React from 'react';
import { Button, Box, Dialog } from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import UserMenu from '../Menus/UserMenu';
import LogIn from './LogIn';
import SignUp from './SignUp';
import { mainTextColor, mainBackgroundColor, textFont, textGradient, secondaryBackgroundColor, secondaryTextColor } from '../../globals';

const UserSection = ({
    user,
    handleUserMenu,
    handleUserMenuClose,
    userMenuOpen,
    userAnchorEl,
    handleSignOut,
    handleOpenLogInDialog,
    handleCloseLogInDialog,
    openLogInDialog,
    handleOpenSignUpDialog,
    handleCloseSignUpDialog,
    openSignUpDialog,
    mainTextColor
}) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="flex-end" flexGrow={1}>
            {user ? 
            <>
                <Button variant="text" sx={{ textTransform: 'none', fontSize: '1.25em', color: mainTextColor }} aria-controls="user-menu" aria-haspopup="true" onClick={handleUserMenu} endIcon={<ArrowDropDown />}>Account</Button>
                <UserMenu user={user} handleUserMenuClose={handleUserMenuClose} userMenuOpen={userMenuOpen} userAnchorEl={userAnchorEl} handleSignOut={handleSignOut}/>
            </> :
            <>
                <Button variant="text" sx={{ marginRight: 2, textTransform: 'none', fontSize: '1.25em', color: mainTextColor }} onClick={handleOpenLogInDialog}>Log in</Button>
                <Dialog onClose={handleCloseLogInDialog} open={openLogInDialog}><Box p={12} sx={{ borderRadius: '100%' }}><LogIn onClose={handleCloseLogInDialog}/></Box></Dialog>
                <Button variant="contained" sx={{ marginRight: 2, color: mainBackgroundColor, backgroundColor: mainTextColor, textTransform: 'none', fontSize: '1.25em', fontWeight: 600, '&:hover': { backgroundColor: mainTextColor, color: mainBackgroundColor } }} onClick={handleOpenSignUpDialog}>Sign Up</Button>
                <Dialog onClose={handleCloseSignUpDialog} open={openSignUpDialog}><Box p={12} sx={{ borderRadius: '100%' }}><SignUp onClose={handleCloseSignUpDialog}/></Box></Dialog>
            </>
            }
        </Box>
    )
};

export default UserSection;
