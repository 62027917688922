import React, { useState, useEffect } from 'react'; // import useEffect here
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Card, List, ListItem, Paper, Button, Grid, Input, Container } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { accentColor } from '../../../globals.js';
import { getCurrentUser, getUser } from "../../firebase.js";
import { Link } from 'react-router-dom';
import UploadBox from '../Shared/UploadBox.js';
import DetailListItem from '../Shared/DetailListItem.js';

const EmploymentContractUpload = () => {
    const [fileInfo, setFileInfo] = useState([]);
    const [wordCount, setWordCount] = useState(null);
    const [textInfo, setTextInfo] = useState(null);
    const [employerInfo, setEmployerInfo] = useState(null);
    const [jobTitle, setJobTitle] = useState(null);
    const [salaryInfo, setSalary] = useState(null);
    const [equityInfo, setEquity] = useState(null);
    const [benefitsInfo, setBenefits] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [paidTimeOff, setPaidTimeOff] = useState(null);
    const [nonCompeteClause, setNonCompeteClause] = useState(null);
    const [locationInfo, setLocationInfo] = useState(null);
    const [bonusInfo, setBonusInfo] = useState(null);
    const [commissionInfo, setCommissionInfo] = useState(null);
    const [nonDisparagementClause, setNonDisparagementClause] = useState(null);
    const [withholdingsInfo, setWithholdingsInfo] = useState(null);
    const [probationalPeriod, setProbationalPeriod] = useState(null);
    const [overtimeInfo, setOvertimeInfo] = useState(null);
    const [terminationPolicy, setTerminationPolicy] = useState(null);
    const [intellectualPropertyRights, setIntellectualPropertyRights] = useState(null);
    const [nonDisclosureAgreements, setNonDisclosureAgreements] = useState(null);

    const isMounted = React.useRef(true);

    const [userDoc, setUserDoc] = useState(null);
    const [user, setUser] = useState(null);
    const [isAbleToScan, setIsAbleToScan] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        // On component unmount, change isMounted to false
        const fetchData = async () => {
            const userDoc = await getUser();
            setUserDoc(userDoc);
            const user = await getCurrentUser();
            setUser(user);
            console.log(user.numberOfScans);
            // Making num of scans 999 for testing purposes for now
            // userDoc.numberOfScans = 999;
            setIsAbleToScan(true);
            // if (userDoc.numberOfScans === 0 || userDoc.paidUser && user.emailVerified) {
            //     setIsAbleToScan(true);
            // } else {
            //     setIsAbleToScan(false);
            // }
        };
        fetchData();

        return () => {
            isMounted.current = false;
        }
    }, []);

    const requestUploadUrl = async (documentName, documentType) => {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        try {
            const response = await axios.get(`${backendUrl}/requestUpload`, {
                params: {
                    documentName,
                    documentType
                }
            });
            return response.data; // This URL is then used to upload directly to S3
        } catch (error) {
            console.error('Error requesting upload URL:', error);
            return null;
        }
    };

    const notifyUploadComplete = async (documentName) => {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        try {
            await axios.post(`${backendUrl}/uploadComplete`, null, {
                params: {
                    documentName
                }
            });
        } catch (error) {
            console.error('Error notifying backend about upload completion:', error);
        }
    };

    const requestDownloadUrl = async (documentName) => {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        try {
            const response = await axios.get(`${backendUrl}/requestDownload`, {
                params: {
                    documentName
                }
            });
            return response.data; // Use this URL to download the file directly from S3
        } catch (error) {
            console.error('Error requesting download URL:', error);
            return null;
        }
    };

    const onDrop = async (acceptedFiles) => {
        setIsLoading(true); // Consider using this to show loading state
    
        // Assuming you're uploading the first file from acceptedFiles
        const file = acceptedFiles[0];
        const documentName = file.name; // Or any naming convention you prefer
        const documentType = "application/pdf"; // This should match your file's MIME type
    
        // Step 1: Request a pre-signed URL from your backend
        const uploadUrl = await requestUploadUrl(documentName, documentType);
    
        if (!uploadUrl) {
            console.error('Failed to get an upload URL');
            setIsLoading(false);
            return;
        }
    
        // Step 2: Upload the file to S3 using the pre-signed URL
        try {
            const result = await axios.put(uploadUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
            });
    
            if (result.status === 200) {
                console.log('File successfully uploaded to S3');
                // Notify your backend that the upload is complete
                await notifyUploadComplete(documentName);
            } else {
                console.error('Failed to upload file to S3');
            }
        } catch (error) {
            console.error('Error during file upload:', error);
        } finally {
            setIsLoading(false); // Hide loading state
        }
    };
    

    // const onDrop = async acceptedFiles => {
    //     setIsLoading(true);
    //     setEmployerInfo(null);
    //     console.log("HEY 1");
    //     if (isMounted.current) setFileInfo(acceptedFiles);

    //     let userId = null;

    //     for (const file of acceptedFiles) {
    //         const formData = new FormData();
    //         formData.append('file', file);

    //         try {
    //             const currentUser = await getCurrentUser();
    //             if (currentUser) {
    //                 userId = currentUser.uid;
    //             } else {
    //                 userId = null;
    //             }

    //             const backendUrl = process.env.REACT_APP_BACKEND_URL; // Accessing the environment variable
    //             var response = await axios.post(`${backendUrl}/upload/employment-contracts-upload/`, formData, {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                     'X-User-ID': userId,
    //                 },
    //             });
    //             console.log(response.data);
    //             var responseData = {}
    //             if (isMounted.current) {
    //                 responseData = {
    //                     employer: response.data.employer,
    //                     job_title: response.data.job_title,
    //                     salary: response.data.salary,
    //                     equity: response.data.equity,
    //                     benefits: response.data.benefits,
    //                     start_date: response.data.start_date,
    //                     paid_time_off: response.data.paid_time_off,
    //                     non_compete_clause: response.data.non_compete_clause,
    //                     location: response.data.location,
    //                     bonus: response.data.bonus,
    //                     commission: response.data.commission,
    //                     non_disparagement_clause: response.data.non_disparagement_clause,
    //                     withholdings_info: response.data.withholdings_info,
    //                     probational_period: response.data.probational_period,
    //                     overtime: response.data.overtime,
    //                     termination_policy: response.data.termination_policy,
    //                     intellectual_property_rights: response.data.intellectual_property_rights,
    //                     non_disparagement_clause: response.data.non_disparagement_clause
    //                 };

    //                 // Update state variables
    //                 setEmployerInfo(responseData.employer);
    //                 setJobTitle(responseData.job_title);
    //                 setSalary(responseData.salary);
    //                 setEquity(responseData.equity);
    //                 setBenefits(responseData.benefits);
    //                 setStartDate(responseData.start_date);
    //                 setPaidTimeOff(responseData.paid_time_off);
    //                 setNonCompeteClause(responseData.non_compete_clause);
    //                 setLocationInfo(responseData.location);
    //                 setBonusInfo(responseData.bonus);
    //                 setCommissionInfo(responseData.commission);
    //                 setNonDisparagementClause(responseData.non_disparagement_clause);
    //                 setWithholdingsInfo(responseData.withholdings_info);
    //                 setProbationalPeriod(responseData.probational_period);
    //                 setOvertimeInfo(responseData.overtime);
    //                 setTerminationPolicy(responseData.termination_policy);
    //                 setIntellectualPropertyRights(responseData.intellectual_property_rights);
    //                 setNonDisclosureAgreements(responseData.non_disparagement_clause);
    //                 setIsLoading(false);


    //             }
    //             // Use responseData for the second API request
    //             const dataToSend = responseData;
    //             // Add any other relevant state variables here
    //             const summaryResponse = await axios({
    //                 url: `${backendUrl}/download/employment-contracts-results-download/`,
    //                 method: 'POST',
    //                 data: dataToSend,
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 responseType: 'blob',
    //             });
    //             console.log(summaryResponse);

    //             const summary_upload = summaryResponse.data;
    //             const original_upload = file;

    //             const uploadFormData = new FormData();
    //             uploadFormData.append('uid', userId);
    //             uploadFormData.append('original_upload', original_upload, original_upload.name);
    //             uploadFormData.append('summary_upload', summary_upload, 'summary.pdf');
    //             uploadFormData.append('name', file.name);
    //             uploadFormData.append('contractType', "Employment Contract");

    //             const uploadFileResponse = await axios.post(`${backendUrl}/upload/upload_user_file/`, uploadFormData, {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data'
    //                 }
    //             });
    //             console.log(uploadFileResponse);
    //         } catch (error) {
    //             console.error('Error uploading file:', error.uploadFileResponse ? error.uploadFileResponse : error);
    //             setIsLoading(false);
    //         }
    //     }
    // };

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: 'application/pdf',
    });


    async function handleButtonClick() {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await axios.get(`${backendUrl}/upload/get-request-demo/`);
        console.log(response);
    }

    const sendData = () => {
        const dataToSend = {
            "employer": employerInfo,
            "job_title": jobTitle,
            "start_date": startDate,
            "location": locationInfo,
            "salary": salaryInfo,
            "equity": equityInfo,
            "benefits": benefitsInfo,
            "bonus": bonusInfo,
            "commission": commissionInfo,
            "overtime": overtimeInfo,
            "paid_time_off": paidTimeOff,
            "withholdings_info": withholdingsInfo,
            "non_compete_clause": nonCompeteClause,
            "non_disparagement_clause": nonDisparagementClause,
            "intellectual_property_rights": intellectualPropertyRights,
            "probational_period": probationalPeriod,
            "termination_policy": terminationPolicy,
            "non_disclosure_agreements": nonDisclosureAgreements
            // Add any other relevant state variables here
        };

        axios({
            url: 'http://localhost:5001/download/employment-contracts-results-download/',
            method: 'POST',
            data: dataToSend,
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Employment_Contract_Responses.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.error(error));
    }

    const contractDetails = {
        generalInformation: [
            { label: 'Employer Info', value: employerInfo },
            { label: 'Job Title', value: jobTitle },
            { label: 'Start Date', value: startDate },
            { label: 'Job Location', value: locationInfo },
            { label: 'Salary', value: salaryInfo },
            { label: 'Equity', value: equityInfo },
            { label: 'Benefits', value: benefitsInfo },
            { label: 'Bonus', value: bonusInfo },
            { label: 'Commission', value: commissionInfo },
            { label: 'Overtime', value: overtimeInfo },
            { label: 'PTO', value: paidTimeOff },
            { label: 'Withholdings', value: withholdingsInfo },
        ],
        importantClauseInformation: [
            { label: 'NDA Policy', value: nonDisclosureAgreements },
            { label: 'Non Compete Clause', value: nonCompeteClause },
            { label: 'Non Disparagement Clause', value: nonDisparagementClause },
            { label: 'Intellectual Property Rights', value: intellectualPropertyRights },
            { label: 'Probational Period', value: probationalPeriod },
            { label: 'Termination Policy', value: terminationPolicy },
        ],
    };

    return (
        <Container maxWidth="lg">
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>

                {employerInfo !== null && (
                    <Grid item xs={12}>
                        <Card sx={{ padding: 2, marginBottom: 2 }}>
                            <Typography variant="h3" sx={{ fontWeight: 'bold', textAlign: 'center', marginTop: 2 }}>
                                Employment Contract Details
                            </Typography>
                            <Typography variant="h6" sx={{ textDecoration: 'underline', marginTop: 2, fontWeight: 'bold' }}>
                                General Information:
                            </Typography>
                            <List>
                                {contractDetails.generalInformation.map((detail, index) => (
                                    <DetailListItem key={index} detail={detail} />
                                ))}
                            </List>
                            <Divider />
                            <Typography variant="h6" sx={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: 2 }}>Important Clause Information:</Typography>
                            <List>
                                {contractDetails.importantClauseInformation.map((detail, index) => (
                                    <DetailListItem key={index} detail={detail} />
                                ))}
                            </List>
                            <Divider />
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                <Button variant="contained" color="primary" sx={{ backgroundColor: accentColor, textTransform: 'none', '&:hover': { backgroundColor: 'transparent', color: accentColor } }}
                                    onClick={() => sendData()}>
                                    Download
                                </Button>
                                <ListItem>
                                    <Button component={Link} to="/settings" variant="contained" color="primary">
                                        View All Scans
                                    </Button>
                                </ListItem>
                            </Box>
                        </Card>
                    </Grid>
                )}
                <UploadBox
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    isAbleToScan={isAbleToScan}
                    fileInfo={fileInfo}
                    isLoading={isLoading}
                    getInputProps={getInputProps}
                    contractType="Employment Contract"
                />
            </Grid>
        </Container >
    );
};

export default EmploymentContractUpload;
