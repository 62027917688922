import React, { useState } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import Sidebar from './Sidebar.js'; // Import your Sidebar component
import ProfileContent from './ProfileContent.js'; // Import your ProfileContent component
import BillingContent from './BillingContent.js'; // Import your BillingContent component
import PreviousScans from './PreviousScans.js'; // Import your PreviousScans component
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor, textGradient, lighterAccentColor } from '../globals.js';

// Import other components as needed

const SECTIONS = {
  Profile: <ProfileContent />,
  Billing: <BillingContent />,
  PreviousScans: <PreviousScans />,
  // ...other sections
};

const AccountPage = () => {
  const [selectedSection, setSelectedSection] = useState('Profile');

  return (
    <Box sx={{ height: '100vh' }}>
      {/* Title at the very top */}
      <Typography
        variant="h2"
        gutterBottom
        fontWeight='700'
        sx={{
          color: mainTextColor,
          fontSize: { xs: '2.8rem', sm: '3.75rem', md: '4.5rem' }, // Scaling font size
          '& span': {
            background: textGradient,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
          textAlign: 'center',
          mb: 3
        }}
      >
        <span>Manage</span> Your Account
      </Typography>


      {/* Page Content */}
      <Grid container>
        {/* Sidebar */}
        <Grid item xs={12} md={3}>
          <Sidebar selectedSection={selectedSection} setSelectedSection={setSelectedSection} sections={Object.keys(SECTIONS)} />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} md={9}>
          <Container>
            {SECTIONS[selectedSection]}
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountPage;