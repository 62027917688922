import React from 'react';
import LeaseUpload from './LeaseUpload.js';
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor, textGradient, lighterAccentColor } from '../../../globals.js';

const Title = styled(Typography)(({ theme }) => ({
    color: mainTextColor,
    fontWeight: 'bold',
  }));

const LeaseAgreements = () => {
    return (
        <Box sx={{ width: '100%', minHeight: '100vh', backgroundColor: mainBackgroundColor }}>
            <Box sx={{ py: 7, px: 5, pb: 1, backgroundColor: mainBackgroundColor }}>

            <Typography 
                    variant="h2" 
                    gutterBottom 
                    fontWeight='700'
                    align='center' // Added this line
                    sx={{
                        fontSize: { xs: '3em', sm: '3.5em', md: '4.5em' },
                        backgroundColor: mainBackgroundColor,
                        '& span': {
                            background: textGradient,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }
                    }}
                > <span>Lease Agreements</span>
                </Typography>
                <Title variant="h5" align="center" gutterBottom sx={{ my: 2 }}>
                    You've found the space, let's ensure it's the perfect fit.
                </Title>
            </Box>
            <Box sx={{ py: 1, px: 3, pb: 5 }}>
                <LeaseUpload/>
            </Box>
        </Box>
    );
}

export default LeaseAgreements;
