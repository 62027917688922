import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Box, Button, TextField, Typography, CardContent, Card,
    Divider, List, ListItem, ListItemText, Modal, Backdrop, Fade, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, textFont, accentColor } from '../globals.js';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, signOut } from "./firebase";
import { useUser } from './Authentication/UserContext';
import { getCurrentUser, getUser } from './firebase.js';
import axios from 'axios';


const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
};

const months = Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('en-US', { month: 'long' }));
const days = Array.from({ length: 31 }, (_, i) => i + 1);
const currentYear = new Date().getFullYear();
const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
const defaultProfileInfo = {
    name: '',
    birthdate: {
        birthdateMonth: '',
        birthdateDay: '',
        birthdateYear: ''
    },
    gender: '',
    // add other properties as needed
};

const UpdateInfoModal = ({ open, handleClose, profileInfo, setProfileInfo }) => {
    const [tempInfo, setTempInfo] = useState(profileInfo || defaultProfileInfo);

    // Update tempInfo whenever the modal opens
    useEffect(() => {
        if (open) {
            setTempInfo(profileInfo);
        }
    }, [open, profileInfo]);

    const handleSave = () => {
        axios.post('http://localhost:5001/upload/post-user-information/', tempInfo)
            .then(response => {
                // Handle success, e.g., display a success message
                setProfileInfo(tempInfo);
                console.log('Data saved successfully:', response.data);
                handleClose();
            })
            .catch(error => {
                // Handle errors, e.g., display an error message
                console.error('Error saving data:', error);
            });
    };

    const handleChange = (e) => {
        if (e.target.name === 'birthdateMonth' || e.target.name === 'birthdateDay' || e.target.name === 'birthdateYear') {
            setTempInfo({
                ...tempInfo,
                birthdate: {
                    ...tempInfo.birthdate,
                    [e.target.name]: e.target.value
                }
            });
        } else {
            setTempInfo({ ...tempInfo, [e.target.name]: e.target.value });
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="update-personal-info-modal"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="update-personal-info-modal" variant="h6" component="h2" gutterBottom>
                    Update Personal Information
                </Typography>
                <Box component="form" noValidate autoComplete="off">
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Name"
                        name="name"
                        value={tempInfo.name}
                        onChange={handleChange}
                    />
                    <Box sx={{ display: 'flex', gap: 2, mb: 2, mt: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="birthdateMonth-label">Month</InputLabel>
                            <Select
                                labelId="birthdateMonth-label"
                                name="birthdateMonth"
                                value={tempInfo.birthdate?.birthdateMonth || ''}
                                onChange={handleChange}
                                label="Month"
                            >
                                {months.map((month, index) => (
                                    <MenuItem key={index} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="birthdateDay-label">Day</InputLabel>
                            <Select
                                labelId="birthdateDay-label"
                                name="birthdateDay"
                                value={tempInfo.birthdate?.birthdateDay || ''}
                                onChange={handleChange}
                                label="Day"
                            >
                                {days.map(day => (
                                    <MenuItem key={day} value={day}>{day}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="birthdateYear-label">Year</InputLabel>
                            <Select
                                labelId="birthdateYear-label"
                                name="birthdateYear"
                                value={tempInfo.birthdate?.birthdateYear || ''}
                                onChange={handleChange}
                                label="Year"
                            >
                                {years.map(year => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="gender-label">Gender</InputLabel>
                        <Select
                            labelId="gender-label"
                            name="gender"
                            value={tempInfo.gender}
                            onChange={handleChange}
                            label="Gender"
                        >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Button variant="contained" onClick={handleSave}>Save Changes</Button>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

const ProfileContent = () => {
    const [openModal, setOpenModal] = useState(false);
    const { user, setUser } = useUser();

    const [profileInfo, setProfileInfo] = useState({
        name: user.displayName,
        birthdate: { birthdateMonth: '', birthdateDay: '', birthdateYear: '' },
        age: user.age,
        gender: user.gender,
        email: user.email,
        password: '***********'
    });

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userData = await getUser();  // Assuming this returns all necessary user info
                console.log("My user data:", userData);
                setUser(userData);  // Update the user state

                if (userData.gender != null) {
                    userData.gender = userData.gender.charAt(0).toUpperCase() + userData.gender.slice(1)
                }
                // Update the profileInfo state with the new user data
                setProfileInfo(prevInfo => ({
                    ...prevInfo,
                    name: userData.displayName,
                    email: userData.email,
                    demographic: userData.demographic,
                    gender: userData.gender,
                    age: userData.age,
                    uid: userData.uid,
                    birthdate: {
                        birthdateMonth: userData.birthdate && userData.birthdate.birthdateMonth ? userData.birthdate.birthdateMonth : "January",
                        birthdateDay: userData.birthdate && userData.birthdate.birthdateDay ? userData.birthdate.birthdateDay : 1,
                        birthdateYear: userData.birthdate && userData.birthdate.birthdateYear ? userData.birthdate.birthdateYear : 1900
                    }
                }));
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();

        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser ? currentUser : null);
        });

        return () => unsubscribe();
    }, []);


    // List of privacy and settings items
    const privacySettings = [
        'Your US State Privacy Rights',
        'Manage Email Notifications',
        'View Subscriber Agreement',
    ];

    return (
        <Card raised sx={{ mx: 'auto' }}>
            <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Your Account</Typography>

                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Personal Info</Typography>
                <Typography variant="body1">{profileInfo.name}</Typography>
                {profileInfo.birthdate.birthdateMonth !== '' ? (
                    <Typography variant="body1">
                        {profileInfo.birthdate.birthdateMonth} {profileInfo.birthdate.birthdateDay}, {profileInfo.birthdate.birthdateYear}
                    </Typography>
                ) : null}
                <Typography variant="body1">{profileInfo.gender}</Typography>
                <Button variant="text" sx={{ display: 'block', mb: 2 }} onClick={handleOpenModal}>UPDATE INFO</Button>
                <UpdateInfoModal
                    open={openModal}
                    handleClose={handleCloseModal}
                    profileInfo={profileInfo}
                    setProfileInfo={setProfileInfo}
                />

                <Divider sx={{ mb: 2 }} />

                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Email</Typography>
                <Typography variant="body1" sx={{ display: 'block', mb: 2 }}>{profileInfo.email}</Typography>
                {/* <Button variant="text" sx={{ display: 'block', mb: 2 }}>CHANGE EMAIL</Button> */}

                <Divider sx={{ mb: 2 }} />

                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Password</Typography>
                <Typography variant="body1">{profileInfo.password}</Typography>
                <Button variant="text" sx={{ display: 'block', mb: 2 }}>CHANGE PASSWORD</Button>

                <Divider sx={{ mb: 2 }} />

                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Privacy And Settings</Typography>
                <List dense>
                    {privacySettings.map((text, index) => (
                        <ListItem key={index} disableGutters component={Link} to={`/settings/${text.toLowerCase().replace(/\s+/g, '-')}`}>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default ProfileContent;
