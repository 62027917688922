import { Box, Button, Container, Card, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getCurrentUser } from './firebase'; // adjust the path as needed
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const PreviousScans = () => {
    const [email, setEmail] = useState('');
    const [uid, setUid] = useState('');
    const [uploads, setUploads] = useState([]);
    useEffect(() => {
        const fetchUserData = async () => {
            const user = await getCurrentUser(); // Call the getCurrentUser function
            if (user) {
                setEmail(user.email);
                setUid(user.uid);
            }
        };
        fetchUserData();
    }, []);

    useEffect(() => {
        if (uid) { // Only run if uid is not null or undefined
            const fetchUserUploads = async () => {
                try {
                    const backendUrl = process.env.REACT_APP_BACKEND_URL; // Accessing the environment variable
                    const response = await axios.get(`${backendUrl}/upload/get_user_uploads/`, {
                        params: {
                            uid: uid,
                        },
                    });
                    console.log(response.data.uploads); // Log the response data
                    const uploads = response.data.uploads;
                    const storage = getStorage();
                    for (let upload of uploads) {

                        const originalRef = ref(storage, upload.data.original_download_url);
                        const summaryRef = ref(storage, upload.data.summary_download_url);

                        // Replace the download URLs with what is returned by the getDownloadURL function
                        upload.data.original_download_url = await getDownloadURL(originalRef);
                        upload.data.summary_download_url = await getDownloadURL(summaryRef);
                    }
                    setUploads(uploads);
                } catch (error) {
                    // Handle the error here
                }
            };
            fetchUserUploads();
        }
    }, [uid]); // Run this useEffect whenever uid changes

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Contract Type</TableCell>
                        <TableCell>Original File</TableCell>
                        <TableCell>Summary</TableCell>
                        <TableCell>Date of Scan</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {uploads.sort((a, b) => new Date(b.data.timestamp) - new Date(a.data.timestamp)).map((upload, index) => {
                        if (!upload.data || !upload.data.original_download_url || !upload.data.summary_download_url) {
                            return null; // Skip this upload if it doesn't have a data property or original_download_url or summary_download_url
                        }
                        const pdfName = upload.data.fileName; // Get the name of the PDF from upload.data.name
                        return (
                            <TableRow key={upload.id}>
                                <TableCell component="th" scope="row">{upload.data.contractType}</TableCell>
                                <TableCell>
                                    <a href={`${upload.data.original_download_url}`} target="_blank" rel="noopener noreferrer">{pdfName} - Original</a>
                                </TableCell>
                                <TableCell>
                                    <a href={`${upload.data.summary_download_url}`} target="_blank" rel="noopener noreferrer">{pdfName} - Summary</a>
                                </TableCell>
                                <TableCell>{new Date(upload.data.timestamp).toLocaleDateString()}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default PreviousScans;
