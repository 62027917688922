import React from 'react';
import PrenupUpload from './PrenupUpload.js';
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor } from '../../../globals.js';

const Title = styled(Typography)(({ theme }) => ({
    color: mainTextColor,
    fontWeight: 'bold',
  }));

const PrenupAgreements = () => {
    return (
        <Box sx={{ width: '100%', minHeight: '100vh' }}>
            <Box sx={{ py: 7, px: 5, pb: 1, width: '100%', textAlign : "center" }}>
            <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', color: '#808080', '& span': { background: 'linear-gradient(90deg, #F59E0B 35%, #CC3E00 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent',}}}>
                    <span>Prenup Analysis</span>
                </Typography>
                <Title variant="h5" align="center" gutterBottom sx={{ my: 2 }}>
                    You've found love, let's square everything away.
                </Title>
            </Box>
            <Box sx={{ py: 1, px: 3, pb: 5 }}>
                <PrenupUpload/>
            </Box>
        </Box>
    );
}

export default PrenupAgreements;
