import { Dialog, DialogTitle, Button, IconButton, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { secondaryBackgroundColor, mainTextColor, accentColor } from '../../globals.js';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const ProductMenu = ({ handleProductsClose, productsOpen }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={productsOpen}
      onClose={handleProductsClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          position: 'absolute',
          top: '10%',
          left: isSmallScreen ? 0 : 'auto',
          right: isSmallScreen ? 0 : 'auto',
          width: isSmallScreen ? '100%' : '30%',
          maxWidth: '400px',
          borderRadius: '10px',
          backgroundColor: secondaryBackgroundColor,
          overflow: 'hidden',
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleProductsClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: mainTextColor,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{ fontSize: '1.5em', fontWeight: 600, color: mainTextColor, textAlign: 'center', padding: theme.spacing(3) }}>
        Product
      </DialogTitle>
      <Box sx={{ padding: theme.spacing(2), display: 'flex', flexDirection: 'column' }}>
        <Button
          onClick={handleProductsClose}
          component={Link}
          to="/employment-contracts"
          startIcon={<PeopleAltIcon />}
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            color: mainTextColor,
            fontSize: '1em',
            fontWeight: 600,
            width: '100%',
            textAlign: 'left',
            '&:hover': {
              backgroundColor: 'transparent',
              color: accentColor,
            },
            mb: 2,
          }}
        >
          Employment Contracts
        </Button>
        <Button
          onClick={handleProductsClose}
          component={Link}
          to="/lease-agreements"
          startIcon={<BusinessCenterIcon />}
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            color: mainTextColor,
            fontSize: '1em',
            fontWeight: 600,
            width: '100%',
            textAlign: 'left',
            '&:hover': {
              backgroundColor: 'transparent',
              color: accentColor,
            },
            mb: 2,
          }}
        >
          Lease Agreements
        </Button>
        {/* <Button
          onClick={handleProductsClose}
          component={Link}
          to="/prenup-agreements"
          startIcon={<LibraryBooksIcon />}
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            color: mainTextColor,
            fontSize: '1em',
            fontWeight: 600,
            width: '100%',
            textAlign: 'left',
            '&:hover': {
              backgroundColor: 'transparent',
              color: accentColor,
            },
            mb: 2,
          }}
        >
          Prenups - Soon
        </Button> */}
      </Box>
    </Dialog>
  );
};

export default ProductMenu;
