import React from 'react';
import { Box, Typography, Container, Card, Avatar, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { mainBackgroundColor, secondaryBackgroundColor, mainTextColor, secondaryTextColor, accentColor } from '../globals.js';

// Using Material UI icons
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HomeIcon from '@mui/icons-material/Home';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

const ProductsPage = () => {
    const products = [
        { title: "Employment Contract Analysis", description: "Analyze your employment contracts or offer letters with us. We ensure that everything is fair and square for both parties. Don't know what to ask? We got you covered.", icon: <BusinessCenterIcon />, link: "/employment-contracts" },
        { title: "Lease Agreement Analysis", description: "Navigate your rental agreement effortlessly with our comprehensive analysis. Learn about important clauses and your rights. Need advice on breaking your lease? We're here to help.", icon: <HomeIcon />, link: "/lease-agreements" },
        { title: "Prenup Analysis", description: "Ensure clarity and peace of mind before signing a prenup. It's more than just a contract, it's one of life's biggest decisions. Let us help you understand the fine print.", icon: <FamilyRestroomIcon />, link: "/prenup-agreements" }
    ];

    return (
        <Container maxWidth="lg" sx={{ my: 6, height: '100vh', mt: '5%', px: 2 }}>
            <Box sx={{ color: mainTextColor, textAlign: 'center' }}>
                <Typography variant="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#808080', '& span': { background: 'linear-gradient(90deg, #F59E0B 35%, #CC3E00 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent',}}}>
                    <span>Products</span>
                </Typography>
            </Box>
            
            {products.map((product, index) => (
                <Card key={index} sx={{ my: 3, mx: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2, backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#e0e0e0' }}>
                    <Box sx={{ pr: 2 }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: mainTextColor }}>
                            {product.title}
                        </Typography>
                        <Typography variant="body1" sx={{ color: mainTextColor, my: 1 }}>
                            {product.description}
                        </Typography>
                        <Button 
                            variant="contained" 
                            sx={{ 
                                backgroundColor: accentColor, 
                                textTransform: 'none', 
                                '&:hover': { 
                                    backgroundColor: accentColor, 
                                    boxShadow: 'none',
                                } 
                            }}
                        >
                            <RouterLink to={product.link} style={{ textDecoration: 'none', color: 'inherit' }}>Learn More</RouterLink>
                        </Button>
                    </Box>
                    <Avatar sx={{ bgcolor: mainTextColor }}>
                        {product.icon}
                    </Avatar>
                </Card>
            ))}
        </Container>
    );
};

export default ProductsPage;
